import {
  ANALYSIS_DRAW_TYPE,
  ANALYSIS_DATA_DRAW,
  ANALYSIS_DATA_DRAW_TYPE,
} from "@/constant/analysis/analysis_draw.js";
import { dataType } from "element-plus/es/components/table-v2/src/common";

// 将分析结果页面的后端数据转换为画图所需数据结构

const colorCard = [
  "#1A78FF",
  "#08B809",
  "#F48F42",
  "#9480E4",
  "#16C8D2",
  "#F2BD42",
  "#D95040",
  "#4A60EB",
  "#12B094",
  "#91BB16",
];

// 频数统计图-柱状图
const analysisBarChart = (rawData, dataType = "frequency") => {
  let data = {
    xAxisData: [],
    series: [],
    yAxis: [],
    xAxis: [],
  };
  let title = {
    title: "",
    xTitle: "",
    yTitle: "",
  };
  let legendColor = [];
  let legendColorOption = [];

  rawData.resultData.data.forEach((element, index) => {
    if (element.childDataList) {
      data.xAxisData.push(element.chartVariableName);
      element.childDataList.forEach((childElement, childIndex) => {
        if (data.series[childIndex]) {
          data.series[childIndex].data.push(childElement[dataType]);
        } else {
          data.series[childIndex] = {};
          data.series[childIndex].data = [];
          data.series[childIndex].name = childElement.chartVariableName;
          data.series[childIndex].stack =
            ANALYSIS_DRAW_TYPE[rawData.resultType].stack;
          data.series[childIndex].type =
            ANALYSIS_DRAW_TYPE[rawData.resultType].type;
          data.series[childIndex].data.push(childElement[dataType]);
        }
      });
    } else {
      data.xAxisData.push(element.chartVariableName);
      if (data.series[0]) {
        data.series[0].data.push(element[dataType]);
        data.series[0].type = ANALYSIS_DRAW_TYPE[rawData.resultType].type;
      } else {
        data.series[0] = {};
        data.series[0].data = [];
        data.series[0].data.push(element[dataType]);
        data.series[0].type = ANALYSIS_DRAW_TYPE[rawData.resultType].type;
      }
    }
  });

  // 设置title
  title.title = rawData.resultData.title;
  if (rawData.resultData.xTitle || rawData.resultData.xtitle) {
    title.xTitle = rawData.resultData.xTitle || rawData.resultData.xtitle;
  }
  if (rawData.resultData.yTitle || rawData.resultData.ytitle) {
    title.yTitle = rawData.resultData.yTitle || rawData.resultData.ytitle;
  }

  // 设置legendColor
  if (data.series.length > 1) {
    legendColor = data.series.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  } else {
    legendColor = data.xAxisData.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  }

  // 设置legendColorOption
  if (data.series.length > 1) {
    legendColorOption = data.series.map((key, index) => {
      return { value: key.name, label: key.name, color: legendColor[index] };
    });
  } else {
    legendColorOption = data.xAxisData.map((key, index) => {
      return { value: key, label: key, color: legendColor[index] };
    });
  }

  return {
    data,
    title,
    legendColor,
    legendColorOption,
  };
};

// 频数统计图-条形图
const analysisBarHorizontalChart = (rawData, dataType = "frequency") => {
  let data = {
    yAxisData: [],
    series: [],
    yAxis: [],
    xAxis: [],
  };
  let title = {
    title: "",
    xTitle: "",
    yTitle: "",
  };
  let legendColor = [];
  let legendColorOption = [];

  rawData.resultData.data.forEach((element, index) => {
    if (element.childDataList) {
      data.yAxisData.push(element.chartVariableName);
      element.childDataList.forEach((childElement, childIndex) => {
        if (data.series[childIndex]) {
          data.series[childIndex].data.push(childElement[dataType]);
        } else {
          data.series[childIndex] = {};
          data.series[childIndex].data = [];
          data.series[childIndex].name = childElement.chartVariableName;
          data.series[childIndex].stack =
            ANALYSIS_DRAW_TYPE[rawData.resultType].stack;
          data.series[childIndex].data.push(childElement[dataType]);
        }
      });
    } else {
      data.yAxisData.push(element.chartVariableName);
      if (data.series[0]) {
        data.series[0].data.push(element[dataType]);
      } else {
        data.series[0] = {};
        data.series[0].data = [];
        data.series[0].data.push(element[dataType]);
      }
    }
  });

  // 设置title
  title.title = rawData.resultData.title;
  if (rawData.resultData.xTitle || rawData.resultData.xtitle) {
    title.xTitle = rawData.resultData.xTitle || rawData.resultData.xtitle;
  }
  if (rawData.resultData.yTitle || rawData.resultData.ytitle) {
    title.yTitle = rawData.resultData.yTitle || rawData.resultData.ytitle;
  }
  // 设置legendColor
  if (data.series.length > 1) {
    legendColor = data.series.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  } else {
    legendColor = data.yAxisData.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  }

  // 设置legendColorOption
  if (data.series.length > 1) {
    legendColorOption = data.series.map((key, index) => {
      return { value: key.name, label: key.name, color: legendColor[index] };
    });
  } else {
    legendColorOption = data.yAxisData.map((key, index) => {
      return { value: key, label: key, color: legendColor[index] };
    });
  }

  return {
    data,
    title,
    legendColor,
    legendColorOption,
  };
};

// 频数统计图-饼图
const analysisPieChart = (rawData, dataType = "frequency") => {
  let data = {
    series: [],
  };
  let title = {
    title: "",
    xTitle: "",
    yTitle: "",
  };
  let legendColor = [];
  let legendColorOption = [];

  rawData.resultData.data.forEach((element, index) => {
    if (data.series[0]) {
      data.series[0].data.push({
        value: element[dataType],
        name: element.chartVariableName,
      });
    } else {
      data.series[0] = {};
      data.series[0].data = [];
      data.series[0].data.push({
        value: element[dataType],
        name: element.chartVariableName,
      });
    }
  });

  data.series[0].radius = ANALYSIS_DRAW_TYPE[rawData.resultType].radius;

  title.title = rawData.resultData.title;
  if (rawData.resultData.xTitle || rawData.resultData.xtitle) {
    title.xTitle = rawData.resultData.xTitle || rawData.resultData.xtitle;
  }
  if (rawData.resultData.yTitle || rawData.resultData.ytitle) {
    title.yTitle = rawData.resultData.yTitle || rawData.resultData.ytitle;
  }

  legendColor = rawData.resultData.data.map((key, index) => {
    return colorCard[index % colorCard.length];
  });

  legendColorOption = rawData.resultData.data.map((key, index) => {
    return {
      value: key.chartVariableName,
      label: key.chartVariableName,
      color: legendColor[index],
    };
  });

  return {
    data,
    title,
    legendColor,
    legendColorOption,
  };
};

// 直方图
const analysisHistogramChart = (rawData, dataType = "frequency") => {
  let data = {
    xAxisData: [],
    series: [],
    yAxis: [],
    xAxis: [],
  };
  let title = {
    title: "",
    xTitle: "",
    yTitle: "",
  };
  let legendColor = [];
  let legendColorOption = [];

  rawData.resultData.histogramData.forEach((element, index) => {
    if (data.series[0]) {
      data.series[0].data.push([
        element.fromValue,
        element.toValue,
        element[dataType],
      ]);
    } else {
      data.series[0] = {};
      data.series[0].data = [];
      data.series[0].type = "custom";
      data.series[0].data.push([
        element.fromValue,
        element.toValue,
        element[dataType],
      ]);
    }
  });

  rawData.resultData.normalDistributionData.forEach((element, index) => {
    if (data.series[1]) {
      data.series[1].data.push([element.xaxisData, element.yaxisData]);
    } else {
      data.series[1] = {};
      data.series[1].data = [];
      data.series[1].data.push([element.xaxisData, element.yaxisData]);
      data.series[1].type = "line";
      data.series[1].alignTicks = true;
      data.series[1].tooltip = {
        trigger: "axis",
      };
    }
  });

  data.series[1].yAxisIndex = 1;
  // data.series[1].xAxisIndex = 1;
  data.series[1].symbolSize = 0;
  data.series[1].type = "line";
  data.yAxis = [{ type: "value", show: false }];
  // data.xAxis = [
  //   {
  //     type: "value",
  //   },
  // ];

  // 设置title
  title.title = rawData.resultData.title;
  if (rawData.resultData.xTitle || rawData.resultData.xtitle) {
    title.xTitle = rawData.resultData.xTitle || rawData.resultData.xtitle;
  }
  if (rawData.resultData.yTitle || rawData.resultData.ytitle) {
    title.yTitle = rawData.resultData.yTitle || rawData.resultData.ytitle;
  }

  // 设置legendColor
  if (data.series.length > 1) {
    legendColor = data.series.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  } else {
    legendColor = data.xAxisData.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  }

  // 设置legendColorOption
  let optionName = { custom: "直方图", line: "正态曲线" };
  if (data.series.length > 1) {
    legendColorOption = data.series.map((key, index) => {
      return {
        value: key.type,
        label: optionName[key.type],
        color: legendColor[index],
      };
    });
  } else {
    legendColorOption = data.xAxisData.map((key, index) => {
      return { value: key, label: key, color: legendColor[index] };
    });
  }

  return {
    data,
    title,
    legendColor,
    legendColorOption,
  };
};

// 正态性分析PPQQ图
const analysisPPQQChart = (rawData) => {
  let data = {
    series: [],
  };
  let title = {
    title: "",
    xTitle: "",
    yTitle: "",
  };
  let legendColor = [];
  let legendColorOption = [];

  rawData.resultData.scatterData.forEach((element, index) => {
    if (data.series[0]) {
      data.series[0].data.push([element.actualValue, element.predictValue]);
    } else {
      data.series[0] = {};
      data.series[0].data = [];
      data.series[0].type = "scatter";
      data.series[0].name = "实际值";
      data.series[0].symbolSize = 8;
      data.series[0].data.push([element.actualValue, element.predictValue]);
    }
  });

  rawData.resultData.lineData.forEach((element, index) => {
    if (data.series[1]) {
      data.series[1].data.push([element.actualValue, element.predictValue]);
    } else {
      data.series[1] = {};
      data.series[1].data = [];
      data.series[1].type = "line";
      data.series[1].name = "预测值";
      data.series[1].symbolSize = 0;
      data.series[1].data.push([element.actualValue, element.predictValue]);
    }
  });

  // 设置title
  title.title = rawData.resultData.title;
  if (rawData.resultData.xTitle || rawData.resultData.xtitle) {
    title.xTitle = rawData.resultData.xTitle || rawData.resultData.xtitle;
  }
  if (rawData.resultData.yTitle || rawData.resultData.ytitle) {
    title.yTitle = rawData.resultData.yTitle || rawData.resultData.ytitle;
  }

  // 设置legendColor
  if (data.series.length > 1) {
    legendColor = data.series.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  } else {
    legendColor = data.xAxisData.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  }

  // 设置legendColorOption
  let optionName = { scatter: "实际值", line: "预测值" };
  if (data.series.length > 1) {
    legendColorOption = data.series.map((key, index) => {
      return { value: key.name, label: key.name, color: legendColor[index] };
    });
  } else {
    legendColorOption = data.xAxisData.map((key, index) => {
      return { value: key, label: key, color: legendColor[index] };
    });
  }

  return {
    data,
    title,
    legendColor,
    legendColorOption,
  };
};

// 碎石图
const analysisScreeChart = (rawData, dataType = "eigenValue") => {
  let data = {
    xAxisData: [],
    series: [],
    yAxis: [],
    xAxis: [],
  };
  let title = {
    title: "",
    xTitle: "",
    yTitle: "",
  };
  let legendColor = [];
  let legendColorOption = [];
  rawData.resultData.data.forEach((element, index) => {
    if (element.childDataList) {
      data.xAxisData.push(element.chartVariableName);
      element.childDataList.forEach((childElement, childIndex) => {
        if (data.series[childIndex]) {
          data.series[childIndex].data.push(childElement[dataType]);
        } else {
          data.series[childIndex] = {};
          data.series[childIndex].data = [];
          data.series[childIndex].name = childElement.chartVariableName;
          data.series[childIndex].stack =
            ANALYSIS_DRAW_TYPE[rawData.resultType].stack;
          data.series[childIndex].type =
            ANALYSIS_DRAW_TYPE[rawData.resultType].type;
          data.series[childIndex].data.push(childElement[dataType]);
        }
      });
    } else {
      data.xAxisData.push(element.chartVariableName);
      if (data.series[0]) {
        data.series[0].data.push(element[dataType]);
        data.series[0].type = ANALYSIS_DRAW_TYPE[rawData.resultType].type;
      } else {
        data.series[0] = {};
        data.series[0].data = [];
        data.series[0].data.push(element[dataType]);
        data.series[0].type = ANALYSIS_DRAW_TYPE[rawData.resultType].type;
      }
    }
  });

  // 设置title
  title.title = rawData.resultData.title;
  if (rawData.resultData.xTitle || rawData.resultData.xtitle) {
    title.xTitle = rawData.resultData.xTitle || rawData.resultData.xtitle;
  }
  if (rawData.resultData.yTitle || rawData.resultData.ytitle) {
    title.yTitle = rawData.resultData.yTitle || rawData.resultData.ytitle;
  }
  // 设置legendColor
  if (data.series.length > 1) {
    legendColor = data.series.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  } else {
    legendColor = data.xAxisData.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  }

  // 设置legendColorOption
  legendColorOption = data.series.map((key, index) => {
    return { value: key.type, label: key.type, color: legendColor[index] };
  });

  return {
    data,
    title,
    legendColor,
    legendColorOption,
  };
};

// 箱线图
const analysisBoxChart = (rawData, dataType = "valueList") => {
  let data = {
    xAxisData: [],
    series: [],
    yAxis: [],
    xAxis: [],
    dataset: [
      {
        source: [],
      },
    ],
  };
  let title = {
    title: "",
    xTitle: "",
    yTitle: "",
  };
  let legendColor = [];
  let legendColorOption = [];
  data.series = [
    {
      name: "boxplot",
      type: "boxplot",
      datasetIndex: 1,
      itemStyle: {
        color: null,
      },
    },
    {
      name: "outlier",
      type: "scatter",
      datasetIndex: 2,
      itemStyle: {
        color: null,
      },
    },
  ];

  let outPointList = [];
  rawData.resultData.data.forEach((element, index) => {
    data.xAxisData.push(element.chartVariableName);
    data.dataset[0].source.push(element[dataType]);
    element.outPoints?.forEach((outPoint) => {
      outPointList.push([element.chartVariableName, outPoint]);
    });
  });

  // 设置title
  title.title = rawData.resultData.title;
  if (rawData.resultData.xTitle || rawData.resultData.xtitle) {
    title.xTitle = rawData.resultData.xTitle || rawData.resultData.xtitle;
  }
  if (rawData.resultData.yTitle || rawData.resultData.ytitle) {
    title.yTitle = rawData.resultData.yTitle || rawData.resultData.ytitle;
  }
  // 设置legendColor
  if (data.series.length > 1) {
    legendColor = data.series.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  } else {
    legendColor = data.xAxisData.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  }

  // 设置legendColorOption
  if (data.series.length > 1) {
    legendColorOption = data.series.map((key, index) => {
      return { value: key.type, label: key.type, color: legendColor[index] };
    });
  } else {
    legendColorOption = data.xAxisData.map((key, index) => {
      return { value: key, label: key, color: legendColor[index] };
    });
  }

  // 在设置颜色后加入异常值
  data.series.push({ type: "scatter", data: outPointList });

  return {
    data,
    title,
    legendColor,
    legendColorOption,
  };
};

// 散点图
const analysisScatterChart = (rawData) => {
  console.log("rawData", rawData);
  let data = {
    xAxisData: [],
    series: [],
  };
  let title = {
    title: "",
    xTitle: "",
    yTitle: "",
  };
  let legendColor = [];
  let legendColorOption = [];

  rawData.resultData.data.forEach((element, index) => {
    if (element.childDataList) {
      data.xAxisData.push(element.chartVariableName);
      element.childDataList.forEach((childElement, childIndex) => {
        if (data.series[index]) {
          data.series[index].data.push([
            childElement.xValue || childElement.xvalue,
            childElement.yValue || childElement.yvalue,
          ]);
        } else {
          data.series[index] = {};
          data.series[index].data = [];
          data.series[index].name = element.chartVariableName;
          data.series[index].type = ANALYSIS_DRAW_TYPE[rawData.resultType].type;
          data.series[index].data.push([
            childElement.xValue || childElement.xvalue,
            childElement.yValue || childElement.yvalue,
          ]);
        }
      });
    } else {
      data.xAxisData.push(element.chartVariableName);
      if (data.series[0]) {
        data.series[0].data.push([
          element.xValue || element.xvalue,
          element.yValue || element.yvalue,
        ]);
      } else {
        data.series[0] = {};
        data.series[0].data = [];
        data.series[0].type = ANALYSIS_DRAW_TYPE[rawData.resultType].type;
        data.series[0].data.push([
          element.xValue || element.xvalue,
          element.yValue || element.yvalue,
        ]);
      }
    }
  });

  // 设置title
  title.title = rawData.resultData.title;
  if (rawData.resultData.xTitle || rawData.resultData.xtitle) {
    title.xTitle = rawData.resultData.xTitle || rawData.resultData.xtitle;
  }
  if (rawData.resultData.yTitle || rawData.resultData.ytitle) {
    title.yTitle = rawData.resultData.yTitle || rawData.resultData.ytitle;
  }
  // 设置legendColor
  if (data.series.length > 1) {
    legendColor = data.series.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  } else {
    legendColor = data.xAxisData.map((key, index) => {
      return colorCard[index % colorCard.length];
    });
  }

  // 设置legendColorOption
  if (data.series.length > 1) {
    legendColorOption = data.series.map((key, index) => {
      return { value: key.name, label: key.name, color: legendColor[index] };
    });
  } else {
    legendColorOption = data.xAxisData.map((key, index) => {
      return { value: key, label: key, color: legendColor[index] };
    });
  }

  return {
    data,
    title,
    legendColor,
    legendColorOption,
    ifZoomXData: rawData.resultData.ifZoomXData,
    xDataList: rawData.resultData.xDataList,
    xAxisType: rawData.resultData.xAxisType,
  };
};

export {
  analysisBarChart,
  analysisBarHorizontalChart,
  analysisPieChart,
  analysisHistogramChart,
  analysisPPQQChart,
  analysisScreeChart,
  analysisBoxChart,
  analysisScatterChart,
};
