import AnalysisResultTable from "@/views/analysis/components/resultComponents/AnalysisResultTable.vue";
import AnalysisResultGroupTable from "@/views/analysis/components/resultComponents/AnalysisResultGroupTable.vue";
import AnalysisResultCommonTable from "@/views/analysis/components/resultComponents/AnalysisResultCommonTable.vue";
import AnalysisResultHeadlessTable from "@/views/analysis/components/resultComponents/AnalysisResultHeadlessTable.vue";

import FrequencyResultChart from "@/views/analysis/components/resultComponents/FrequencyResultChart.vue";
import NormalityResultChart from "@/views/analysis/components/resultComponents/NormalityResultChart.vue";

export const ANALYSIS_CHART = {
  COMMON_TABLE: 1, //常规表格
  GROUP_TABLE: 2, //分组表格
  HEADLESS_TABLE: 3, //无表头表格
  FREQUECY_CHART: 101, //频数统计图
  CROSS_FREQUECY_CHART: 102, //交叉频数统计图
  HISTOGRAM_CHART: 103, // 直方图
  PPQQ_CHART: 104, //P-P/Q-Q图
  SCREE_CHART: 105, //碎石图
  BOX_CHART: 106, // 箱线图
  SCATTER_CHART: 107, //散点图
  LINE_CHART: 108, //折线图
};

// 记录resulttype和其相关信息的映射关系
export const ANALYSIS_CHART_MAP = {
  [ANALYSIS_CHART.COMMON_TABLE]: { component: AnalysisResultTable },
  [ANALYSIS_CHART.GROUP_TABLE]: {
    component: AnalysisResultTable,
  },
  [ANALYSIS_CHART.HEADLESS_TABLE]: { component: AnalysisResultTable },
  [ANALYSIS_CHART.FREQUECY_CHART]: { component: FrequencyResultChart },
  [ANALYSIS_CHART.CROSS_FREQUECY_CHART]: { component: FrequencyResultChart },
  [ANALYSIS_CHART.HISTOGRAM_CHART]: { component: NormalityResultChart },
  [ANALYSIS_CHART.PPQQ_CHART]: { component: NormalityResultChart },
  [ANALYSIS_CHART.SCATTER_CHART]: { component: NormalityResultChart },
  [ANALYSIS_CHART.SCREE_CHART]: { component: NormalityResultChart },
  [ANALYSIS_CHART.BOX_CHART]: { component: NormalityResultChart },
  [ANALYSIS_CHART.LINE_CHART]: { component: NormalityResultChart },
};

// 记录表格类型的映射关系
export const ANALYSIS_RESULT_TABLE_MAP = {
  [ANALYSIS_CHART.COMMON_TABLE]: {
    component: AnalysisResultCommonTable,
  },
  [ANALYSIS_CHART.GROUP_TABLE]: {
    component: AnalysisResultGroupTable,
  },
  [ANALYSIS_CHART.HEADLESS_TABLE]: {
    component: AnalysisResultGroupTable,
    ifHideHead: true,
  },
};
