<template>
  <div class="layout-wrapper">
    <BaseHeader></BaseHeader>
    <div class="header-wapper" v-if="!ifHidePageHeader">
      <div class="breadcrumb">
        <BaseBreadcrumb
          :breadcrumbRoutesList="breadcrumbRoutesList"
        ></BaseBreadcrumb>
        <div class="pageTitle">
          {{ pageTitle }}
        </div>
      </div>
    </div>
    <!-- 列表页面 -->
    <div
      v-if="!ifAnalysisPage"
      :class="{ 'main-wrapper': true, 'hide-padding': ifHidePadding }"
    >
      <router-view
        class="content-wrapper"
        :key="$route.fullPath"
        @setBreadcrumbList="setBreadcrumbList"
        @setPageTitle="setPageTitle"
      ></router-view>
      <div class="footer-wrapper" v-if="!ifHideFooter">
        <BaseFooter></BaseFooter>
      </div>
    </div>
    <!-- 分析模块 -->
    <div v-else class="analysis-main-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from "vue";

import { useRoute } from "vue-router";
import { getCurrentInstance } from "vue";
import { useUserInfo } from "@/utils/useUserInfo";
import { httpPost } from "@/api/httpService";

const { proxy } = getCurrentInstance();

import BaseHeader from "./BaseHeader.vue";
import BaseFooter from "./BaseFooter.vue";
import BaseBreadcrumb from "./BaseBreadcrumb.vue";
const route = useRoute();
const ifAnalysisPage = computed(() => {
  return route.name == "AnalysisIndex";
});

//面包屑数据
const breadcrumbRoutesList = ref([]);
const setBreadcrumbList = (_breadcrumbList) => {
  breadcrumbRoutesList.value = _breadcrumbList;
  // breadcrumbRoutesList.value = Object.values(_breadcrumbList);
};

//控制页面数据头部展示
const ifHidePageHeader = ref(false);
watch(
  route,
  (route, oldRoute) => {
    ifHidePageHeader.value = route.meta.ifHidePageHeader;
  },
  {
    immediate: true,
    deep: true,
  }
);

// 控制页边距
const ifHidePadding = ref(false);
watch(
  route,
  (route, oldRoute) => {
    ifHidePadding.value = route.meta.ifHidePadding;
  },
  { immediate: true, deep: true }
);

// 控制页脚
const ifHideFooter = ref(false);
watch(
  route,
  (route, oldRoute) => {
    ifHideFooter.value = route.meta.ifHideFooter;
  },
  { immediate: true, deep: true }
);
//显示类型描述
//面包屑的数据监听

const pageTitle = ref("");
const setPageTitle = (_pageTitle) => {
  pageTitle.value = _pageTitle;
};

// 调用permission接口，
const getUserPermission = async () => {
  const { userId } = useUserInfo();
  // console.log("🚀 ~ getUserPermission ~ userId:", userId);
  let ifLogin = window.sessionStorage.getItem("ifLoginSTAT_" + userId);
  if (ifLogin === "true" || !userId) {
    return;
  }

  let res = await httpPost("/user/v1/permission", null);
  // console.log("🚀 ~ getUserPermission ~ res:", res);
  if (res?.code == 0) {
    window.sessionStorage.setItem("ifLoginSTAT_" + userId, true);
  }
};
onMounted(() => {
  setTimeout(() => {
    getUserPermission();
  }, 1000);
});
</script>

<style lang="scss" scoped>
.layout-wrapper {
  min-width: $min-layout-wrapper-width;
  .header-wapper {
    width: 100%;
    height: $breadcrumb-header-height;
    padding: 0 20px;
    .breadcrumb {
      height: 100%;
      padding: 20px 0;
      text-align: left;
      // align-items: center;
      .el-breadcrumb__inner.is-link {
        color: rgba(108, 108, 108, 1);
        font-size: 14px;
      }

      .pageTitle {
        margin-top: 10px;
        color: rgba(16, 16, 16, 1);
        font-size: 18px;
        font-family: SourceHanSansSC-bold;
        font-weight: 600;
      }
    }
  }
  .main-wrapper {
    background: $main-background;
    padding: $main-page-wrapper-padding;
    .content-wrapper {
      min-height: $main-height;
      background: $main-page-background;
      // min-height: $main-page-height;
      padding: $main-page-wrapper-padding;
    }
  }
  .analysis-main-wrapper {
    background: $main-background;
    height: $analysis-main-height;
  }
  .hide-padding {
    padding: 0px;
  }
}
</style>
