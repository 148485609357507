<template>
  <div class="analysis-layout-wrapper">
    <div class="left-container">
      <AnalysisSideMenu @clickItem="onClickAnalysisItem"></AnalysisSideMenu>
    </div>
    <div class="right-container">
      <BaseTabs
        v-model="activeTabId"
        :tab-list="analysisTabList"
        @change="handleChangeTab"
        @close="handleCloseTab"
      ></BaseTabs>
      <div class="analysis-page-wrapper">
        <template v-if="ifAnalysisListTab">
          <AnalysisList
            @analysisInfo="onClickAnalysisList"
            @analysisItemCondition="onClickAnalysisCondition"
            @analysisItemResult="onClickAnalysisResult"
            @refreshTabs="refreshTabs"
          ></AnalysisList>
        </template>
        <template v-else>
          <el-radio-group type="button" v-model="analysisPageType" size="small">
            <el-radio-button :label="ANALYSIS_PAGE_TYPE.CONDITION">
              分析模型
            </el-radio-button>
            <el-radio-button :label="ANALYSIS_PAGE_TYPE.RESULT">
              分析结果
            </el-radio-button>
          </el-radio-group>
          <component
            :is="currentComponent"
            :key="activeTabId"
            :class="
              analysisPageType === ANALYSIS_PAGE_TYPE.CONDITION
                ? 'condition-page-content'
                : 'result-page-content'
            "
            :tabInfo="activeTab"
            @goToCondtion="changeAnalysisPageTypeToCondition"
            @showLoading="showFullScreenLoading"
          ></component>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  provide,
  reactive,
  ref,
  watch,
  getCurrentInstance,
  onMounted,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";

import AnalysisSideMenu from "./AnalysisSideMenu.vue";
import AnalysisList from "./components/AnalysisList.vue";
import AnalysisResult from "./components/resultComponents/AnalysisResult.vue";
import BaseTabs from "@/components/base/BaseTabs.vue";

import { ANALYSIS_COMPONENT_MAP } from "@/constant/analysis/analysis_type.js";

import * as ws from "@/utils/websocket.js";
import { useUserInfo } from "@/utils/useUserInfo";
import { ElLoading, ElNotification } from "element-plus";

const { proxy } = getCurrentInstance();

const route = useRoute();
const libraryId = route.params.libraryId;

provide("libraryId", libraryId);

// TODO 分析列表tab的id
const ANALYSIS_LIST_ID = 0;
const ANALYSIS_PAGE_TYPE = {
  CONDITION: 0,
  RESULT: 1,
};
const analysisPageType = ref(ANALYSIS_PAGE_TYPE.CONDITION);
const changeAnalysisPageTypeToCondition = () => {
  analysisPageType.value = ANALYSIS_PAGE_TYPE.CONDITION;
};
const activeTabId = ref(ANALYSIS_LIST_ID); // 当前显示分析id
const activeTab = computed(() => {
  // 当前显示页面
  return analysisTabList.value.find((tab) => tab.id == activeTabId.value);
});
const ifAnalysisListTab = computed(() => {
  return activeTabId.value == ANALYSIS_LIST_ID;
});
const analysisTabList = ref([
  { name: "分析列表", unclosable: true, id: ANALYSIS_LIST_ID },
]);

const currentComponent = computed(() => {
  if (analysisPageType.value == ANALYSIS_PAGE_TYPE.CONDITION) {
    return ANALYSIS_COMPONENT_MAP[activeTab.value.algorithmId] || null;
  } else {
    //TODO 结果页面
    return AnalysisResult;
  }
});
//页面切换回调函数
const handleChangeTab = (tab) => {
  analysisPageType.value = ANALYSIS_PAGE_TYPE.CONDITION;
  // currentComponent.value = ANALYSIS_COMPONENT_MAP[tab.algorithmId];
};
const handleCloseTab = (tab) => {
  analysisTabList.value = analysisTabList.value
    .filter((tabItem) => tabItem.id != tab.id)
    .map((tabItem, index) => {
      tabItem.id = index;
      return tabItem;
    });
  if (activeTabId.value >= analysisTabList.value.length) {
    activeTabId.value = analysisTabList.value.length - 1;
  }
};

// 添加tab，tabId不存在时则添加tab，存在时则跳转
const addTab = (analysis, _analysisPageType = ANALYSIS_PAGE_TYPE.CONDITION) => {
  console.log("addtab", analysis);
  let tabAnalysisId = analysis.analysisId;
  let tabId = analysis.id;
  let tab = analysisTabList.value.find((item) => {
    return item.analysisId == tabAnalysisId ? { item } : false;
  }); // 查找当前列表中是否有
  if (!tab) {
    analysisTabList.value.push(analysis);
    activeTabId.value = tabId;
  } else {
    activeTabId.value = tab.id;
  }
  analysisPageType.value = _analysisPageType;
};

// 更新Tabs
const refreshTabs = (newTabList) => {
  const updataAnalysisTabList = analysisTabList.value.map((element) => {
    if (element.analysisId == newTabList.analysisId) {
      // 更改满足条件的元素的属性
      return { ...element, name: newTabList.rename };
    }
    // 不满足条件的元素保持不变
    return element;
  });
  analysisTabList.value = updataAnalysisTabList;
};
// 列表点击分析名称
const onClickAnalysisList = (analysis) => {
  if (analysis.state == 2) {
    addTab(
      { ...analysis, id: analysisTabList.value.length },
      ANALYSIS_PAGE_TYPE.RESULT
    );
  } else {
    addTab({ ...analysis, id: analysisTabList.value.length });
  }
};

// 列表点击分析条件
const onClickAnalysisCondition = (analysis) => {
  addTab({ ...analysis, id: analysisTabList.value.length });
};
// 列表点击分析结果
const onClickAnalysisResult = (analysis) => {
  addTab(
    { ...analysis, id: analysisTabList.value.length },
    ANALYSIS_PAGE_TYPE.RESULT
  );
};

// 点击左侧分析
const onClickAnalysisItem = async (analysis) => {
  let analysisDetail = await createAnalysis(analysis.algorithmId);
  addTab({ ...analysis, ...analysisDetail, id: analysisTabList.value.length });
};

// 创建分析
const createAnalysis = async (algorithmId) => {
  let params = {
    libId: libraryId,
    algorithmId: algorithmId,
  };
  let res = await httpPost("/analysis/v1/create", params);
  if (res.code == 0) {
    proxy.message.success(res.message);
    return { analysisId: res.data.id, name: res.data.name };
  }
  return {};
};

let fullScreenLoading = null;
let fullScreenLoadingTimer = null;
const showFullScreenLoading = () => {
  fullScreenLoading = ElLoading.service({
    lock: true,
    text: "分析进行中...",
    background: "rgba(248, 248, 250, 0.6)",
  });

  fullScreenLoadingTimer = setTimeout(() => {
    console.log("stime");
    proxy.message.warning(
      "此分析可能需要较长的时间，系统将继续为您执行分析操作"
    );
    hideFullScreenLoading();
  }, 10000);
};
const hideFullScreenLoading = () => {
  console.log("hide");
  clearTimeout(fullScreenLoadingTimer);
  fullScreenLoading.close();
};

const WS_MESSAGE_TYPE = {
  ANALYSIS_RESULT: 101,
};
// 简历websocket
let userInfo = useUserInfo();
const getMsg = (res) => {
  res = JSON.parse(res);
  console.log("getmsg", res.messageType);
  switch (res.messageType) {
    case WS_MESSAGE_TYPE.ANALYSIS_RESULT:
      handleReceiveAnalysisResult(res);
      break;
  }
};
const handleReceiveAnalysisResult = (res) => {
  const {
    userId,
    analysisId,
    analysisStatusCode,
    analysisName,
    message,
    algorithmId,
  } = res;
  // // 如果分析结果不是当前用户的则跳过
  if (userId != userInfo.userId) {
    // hideFullScreenLoading();
    return;
  }
  // 如果返回结果是当前页面的分析结果
  if (analysisId == activeTab.value.analysisId) {
    hideFullScreenLoading();
    if (analysisStatusCode == 0) {
      analysisPageType.value = ANALYSIS_PAGE_TYPE.RESULT;
    } else {
      ElNotification({
        title: "分析异常",
        message: message,
        type: "warning",
      });
    }
  } else {
    //如果返回结果非当前页面分析结果
    if (analysisStatusCode == 0) {
      ElNotification({
        title: "分析已完成",
        dangerouslyUseHTMLString: true,
        message: `<a>${analysisName}已完成</a>`,
        type: "success",
        onClick() {
          console.log("onclick");
          addTab(
            {
              name: analysisName,
              analysisId: analysisId,
              id: analysisTabList.value.length,
              algorithmId,
            },
            ANALYSIS_PAGE_TYPE.RESULT
          );
        },
      });
    } else {
      ElNotification({
        title: "分析异常",
        message: message,
        type: "warning",
      });
    }
  }
};

onMounted(() => {
  ws.openSocket(userInfo.userId, getMsg);
});
</script>

<style lang="scss" scoped>
.analysis-layout-wrapper {
  $left-container-width: 270px;
  min-height: 400px !important;
  height: 100%;

  background: $main-background !important;
  display: flex;
  .left-container {
    width: $left-container-width;
    background: $main-page-background;
    // height: calc(100vh - $header-height);
  }
  .right-container {
    width: calc(100vw - $left-container-width);
    min-width: 800px;
    display: flex;
    flex-direction: column;
    padding: $analysis-layout-padding;

    .analysis-page-wrapper {
      // flex: 1
      height: calc(100% - $analysis-layout-padding - $tab-bar-height);
      padding: 18px;
      background: #ffffff;
      // display: flex;
      flex-direction: column;
      overflow: auto;
      .el-radio-group {
        border-radius: 36px;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.4);
        .el-radio-button {
          :deep(.el-radio-button__inner) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90px;
            height: 32px;
            border: 0px;
          }
        }
        .is-active {
          :deep(.el-radio-button__inner) {
            display: flex;
            align-items: center;
            height: 32px;
            border-radius: 36px;
          }
        }
      }
      .condition-page-content {
        height: calc(100% - 32px);
        padding: 10px;
      }
      .result-page-content {
        min-height: calc(100% - 32px);
        padding: 10px;
      }
    }
  }
}
</style>
