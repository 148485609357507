<!-- 普通表格， 不涉及到单元格合并，支持表头合并 -->
<template>
  <native-table
    :table-titles="tableData.tableTitles"
    :span-method="arraySpanMethod"
    ref="tableRef"
    :rowData="tableData.tableColumns"
    :edit="editMap"
    :predict="predictMap"
    :show-header="ifShowHeader"
  ></native-table>
  <!-- <el-table
    :data="tableData.tableColumns"
    header-cell-class-name="header"
    :cell-class-name="tableRowClassName"
  >
    <TableColumn
      v-for="titleItem in tableData.tableTitles"
      :key="titleItem.name"
      :col="titleItem"
      :edit="editMap"
    ></TableColumn>
  </el-table> -->
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  computed,
  defineExpose,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import TableColumn from "@/components/table/tableColumn.vue";
import NativeTable from "@/components/nativeTable/nativeTable.vue";

const props = defineProps({
  tableData: {
    type: Object,
    default: () => {},
  },
});

//表格实例
const tableRef = ref(null);

const bottonLineMap = ref({});
const editMap = ref({});

props.tableData.tableColumns.forEach((element, index) => {
  Object.entries(element).forEach(([key, value]) => {
    if (!bottonLineMap.value[key]) {
      bottonLineMap.value[key] = [];
    }
    bottonLineMap.value[key][index] =
      value && value.botton_line ? value.botton_line : 0;

    if (!editMap.value[key]) {
      editMap.value[key] = [];
    }
    editMap.value[key][index] = value && value.edit ? value.edit : 0;
  });
});

const tableRowClassName = ({ row, column, rowIndex, columnIndex }) => {
  return bottonLineMap.value[column.property]
    ? bottonLineMap.value[column.property][rowIndex]
      ? "rowclass2"
      : "rowclass"
    : "rowclass";
};

defineExpose({ tableRef });
</script>

<style lang="scss" scoped>
.el-table {
  border: 0px;
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
  background: #fff !important;

  :deep(.header) {
    // background: yellow;
    color: rgba(16, 16, 16, 1);
    border: 0px;
    border-bottom: 1px solid #000000 !important;
    background: #fff !important;
    user-select: auto;
  }
  :deep(.rowclass) {
    border: 0px;
    border-bottom: 0px solid #000000 !important;
    color: #000000;
    // background: red;
  }
  :deep(.rowclass2) {
    border: 0px;
    border-bottom: 1px solid #000000 !important;
    color: #000000;
    // background: red;
  }
}
.el-table--border::after {
  width: 0px;
  background: #fff;
}
.el-table--border::before {
  width: 0px;
  background: #fff;
}
:deep(.el-table__border-left-patch) {
  width: 0px;
  background: #fff;
}
</style>
