<template>
  <div class="analysis-result-tabel">
    <div class="analysis-result-tabel-select" id="componentNotToPrint">
      <!-- TODO -->
      <el-dropdown class="analysis-result-tabel-content">
        <span>
          <el-button type="primary" plain>
            <i class="iconfont icon-fuzhi-fenxijieguo"></i>
            复制
            <el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="copyTableToClipboard(componentRef)">
              复制表格
            </el-dropdown-item>
            <el-dropdown-item @click="exportTableToImage(componentRef)">
              导出图片
            </el-dropdown-item>
            <el-dropdown-item @click="exportTableToExcel(componentRef)">
              导出为Excel
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="analysis-result-tabel-view">
      <component
        :is="currentComponent"
        ref="componentRef"
        :tableData="data.resultData"
        :ifShowHeader="ifShowHeader"
      ></component>
      <div class="note">
        {{ data.resultData.tableNote }}
      </div>
      <!-- {{ data }} -->
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  defineProps,
  computed,
  onMounted,
} from "vue";

import { ANALYSIS_RESULT_TABLE_MAP } from "@/constant/analysis/analysis_chart.js";
import { copyTableToClipboard } from "@/utils/copyToClipboard.js";
import {
  exportTableToImage,
  exportTableToExcel,
} from "@/utils/exportAnalysisResult.js";

const componentRef = ref(null);

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
});

const currentComponent = computed(() => {
  return ANALYSIS_RESULT_TABLE_MAP[props.data.resultType]?.component;
});
const ifShowHeader = computed(() => {
  return !ANALYSIS_RESULT_TABLE_MAP[props.data.resultType]?.ifHideHead;
});
</script>

<style lang="scss" scoped>
.analysis-result-tabel {
  position: relative;
  .analysis-result-tabel-select {
    display: flex;
    justify-content: flex-end;
    // font-weight: bold;
    i:first-child {
      padding-right: 8px;
    }
    .analysis-result-tabel-content {
      position: absolute;
      top: -50px;
      height: 32px;
    }
  }
  .analysis-result-tabel-view {
    // background-color: #af9999;
    .note {
      background: #fff;
      font-size: 14px;
    }
  }
}
</style>
