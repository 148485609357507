import message from "@/utils/message";
import useClipboard from "vue-clipboard3";
const { toClipboard } = useClipboard();

//复制文段
const copyTextToClipboard = (textToCopy) => {
  // 创建一个DOM 元素，包含要复制的内容
  const copyContent = document.createElement("div");
  copyContent.innerHTML = textToCopy;
  // 将元素插入到文档中
  document.body.appendChild(copyContent);

  // 复制到剪切板
  toClipboard(copyContent)
    .then(() => {
      message.success("分析已复制到剪贴板");
    })
    .catch((error) => {
      console.error("复制到剪贴板失败:", error);
    });

  // 将元素从文档中移除
  document.body.removeChild(copyContent);
};

//复制表格
const copyTableToClipboard = (ref) => {
  const tableToCopy = ref.tableRef.$el; //表格
  const noteToCopy = ref.noteRef; //表格下方的提示文本
  // 创建一个DOM 元素，包含要复制的内容
  const copyContent = document.createElement("div");
  copyContent.appendChild(tableToCopy.cloneNode(true));
  if (noteToCopy) {
    copyContent.appendChild(noteToCopy.cloneNode(true));
  }
  // 将元素插入到文档中
  document.body.appendChild(copyContent);
  // const parentElement = tableToCopy.parentElement;

  // 复制到剪切板
  toClipboard(copyContent)
    .then(() => {
      message.success("表格已复制到剪贴板");
    })
    .catch((error) => {
      console.error("复制到剪贴板失败:", error);
    });

  //将元素从文档中移除
  document.body.removeChild(copyContent);

  // selectElementContents(copyContent);
  // 复制选中的内容到剪贴板
  // document.execCommand("copy");
};
// // 选中内容
// const selectElementContents = (element) => {
//   const range = document.createRange();
//   range.selectNodeContents(element);
//   const selection = window.getSelection();
//   selection.removeAllRanges();
//   selection.addRange(range);
// };

export { copyTextToClipboard, copyTableToClipboard };
