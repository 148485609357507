<template>
  <!-- 饼状图 -->
  <div ref="chartRef" class="chart"></div>
</template>

<script setup>
import * as echarts from "echarts";
import {
  onMounted,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  watch,
  computed,
} from "vue";
import { generateGradientColors } from "@/utils/color.js";

const props = defineProps({
  data: {
    type: Object,
    default: () => {
      return {
        seriesData: [],
      };
    },
  },
  title: {
    type: Object,
    default: () => {
      return {
        title: "",
        xTitle: "",
        yTitle: "",
      };
    },
  },
  legendColor: {
    type: Object,
    default: () => {
      return [];
    },
  },
});
const emits = defineEmits(["legendColor"]);

const chartRef = ref(null);
let myChart;
let chartOption = ref({});
let defaultOption = {
  title: {
    text: "",
    left: "center",
  },
  tooltip: {
    trigger: "item",
  },
  legend: {
    type: "scroll",
    top: "10%",
  },
  grid: {
    top: "40%",
  },
  series: [
    {
      name: "",
      type: "pie",
      radius: null,
      center: ["50%", "50%"],
      data: [],
      itemStyle: {
        color: function (params) {
          // 自定义颜色
          var colorList = ["#1A78FF", "#08B809"];
          return colorList[params.dataIndex];
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

const chartData = ref(null);
const chartTitle = ref(null);
const chartLegendColor = ref(null);

onMounted(() => {
  initChartView();
});

const initChartView = () => {
  myChart = echarts.init(chartRef.value);
  chartOption.value = defaultOption;

  chartOption.value.title.text = chartTitle.value.title;
  if (chartOption.value.xAxis) {
    chartOption.value.xAxis.name = chartTitle.value.xTitle;
  }
  if (chartOption.value.yAxis) {
    chartOption.value.yAxis.name = chartTitle.value.yTitle;
  }

  chartOption.value.series[0] = {
    ...chartOption.value.series[0],
    ...chartData.value.series[0],
  };

  chartOption.value.series[0].itemStyle.color = function (params) {
    // 自定义颜色
    var colorList = chartLegendColor.value;
    return colorList[params.dataIndex];
  };

  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

const setChartData = () => {
  chartOption.value.series[0] = {
    ...chartOption.value.series[0],
    ...chartData.value.series[0],
  };

  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

// 设置图标题
const setChartTitle = () => {
  chartOption.value.title.text = chartTitle.value.title;
  if (chartOption.value.xAxis) {
    chartOption.value.xAxis.name = chartTitle.value.xTitle;
  }
  if (chartOption.value.yAxis) {
    chartOption.value.yAxis.name = chartTitle.value.yTitle;
  }
  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

const setLegendColor = () => {
  chartOption.value.series[0].itemStyle.color = function (params) {
    // 自定义颜色
    var colorList = chartLegendColor.value;
    return colorList[params.dataIndex];
  };

  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

const exportChart = (type) => {
  let imgUrl = myChart.getDataURL({
    type: type,
    backgroundColor: "#fff",
    pixelRatio: 2,
  });
  var title = chartOption.value.title.text;
  var name =
    title == "" || title == null || title == undefined
      ? new Date().getTime()
      : title;
  if (window.navigator.msSaveOrOpenBlob) {
    var bstr = atob(imgUrl.split(",")[1]);
    var n = bstr.length;
    var u8arr = new Blob([u8arr]);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    if (type == "jpeg" || type == "jpg") {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".jpg");
    } else {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".png");
    }
  } else {
    const a = document.createElement("a");
    a.href = imgUrl;
    if (type == "jpeg" || type == "jpg") {
      a.setAttribute("download", name + ".jpg");
    } else {
      a.setAttribute("download", name + ".png");
    }
    a.click();
  }
};

watch(
  () => props.data,
  (newVal, oldVal) => {
    chartData.value = JSON.parse(JSON.stringify(props.data));
    if (myChart) {
      setChartData();
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.title,
  (newVal, oldVal) => {
    chartTitle.value = JSON.parse(JSON.stringify(props.title));
    if (myChart) {
      setChartTitle();
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.legendColor,
  (newVal, oldVal) => {
    chartLegendColor.value = JSON.parse(JSON.stringify(props.legendColor));
    if (myChart) {
      setLegendColor();
    }
  },
  { immediate: true, deep: true }
);

defineExpose({ exportChart });
</script>

<style lang="scss" scoped></style>
