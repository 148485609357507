<template>
  <div>
    <el-collapse v-model="chartOption" accordion>
      <el-collapse-item title="导出" name="1">
        <div class="analysis-result-chart-option-button">
          <el-button @click="saveImage('png')">PNG</el-button>
          <el-button @click="saveImage('jpg')">JPG</el-button>
        </div>
      </el-collapse-item>
      <el-collapse-item title="标题" name="2">
        <div>
          <el-input
            clearable
            placeholder="请输入标题"
            v-model="chartTitle.title"
            @input="handleChangeTitle"
          ></el-input>
        </div>
      </el-collapse-item>
      <el-collapse-item title="图例" name="5">
        <div class="legend-color">
          <el-select v-model="legendItem" placeholder="请选择">
            <el-option
              v-for="item in chartLegendColorOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-color-picker
            v-model="legendColor"
            @change="handleChangeLegendColor"
          />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  watch,
  defineExpose,
} from "vue";

const props = defineProps({
  title: {
    type: Object,
    default: () => {
      return {
        title: "",
        xTitle: "",
        yTitle: "",
      };
    },
  },
  legendColorOption: {
    type: Object,
    default: () => {
      return [];
    },
  },
});
const emits = defineEmits([
  "saveImage",
  "handleChangeLegendColor",
  "handleChangeTitle",
]);
const chartOption = ref("");
const chartLegendColorOption = ref(null);
const chartTitle = ref({
  title: "",
  xTitle: "",
  yTitle: "",
});

// 图例选择
const legendItem = ref("");
const legendColor = ref("");

watch(
  () => props.title,
  (newVal, oldVal) => {
    chartTitle.value = JSON.parse(JSON.stringify(props.title));
    chartLegendColorOption.value = JSON.parse(
      JSON.stringify(props.legendColorOption)
    );
    legendItem.value = "";
    legendColor.value = "";
  },
  { immediate: true, deep: true }
);

watch(
  () => legendItem,
  (newVal, oldVal) => {
    let selectedlegend = chartLegendColorOption.value.find(
      (item) => item.value == legendItem.value
    );
    if (selectedlegend) {
      legendColor.value = selectedlegend.color;
    }
  },
  { immediate: true, deep: true }
);

const handleChangeTitle = () => {
  emits("handleChangeTitle", chartTitle.value);
};

const handleChangeLegendColor = () => {
  let newchartLegendColorOption = chartLegendColorOption.value.map(
    (element) => {
      if (element.value == legendItem.value) {
        return { ...element, color: legendColor.value };
      } else {
        return element;
      }
    }
  );
  let legendColorList = newchartLegendColorOption.map((item) => item.color);
  emits("handleChangeLegendColor", legendColorList);
  chartLegendColorOption.value = newchartLegendColorOption;
};

const saveImage = (type) => {
  emits("saveImage", type);
};
</script>

<style lang="scss" scoped>
.legend-color {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .el-select {
    margin-right: 10px;
  }
}
</style>
