<template>
  <div class="title">
    <div class="icon">
      <el-image
        v-if="ifImage"
        fit="contain"
        :style="{ width: iconSize + 'px', height: iconSize + 'px' }"
        :src="require('@/assets/imgs/' + icon)"
      ></el-image>
      <i
        v-else
        :class="`iconfont ${icon}`"
        :style="{ color: iconColor, fontSize: iconSize + 'px' }"
      ></i>
    </div>
    <span
      class="title-text"
      :style="{
        color: fontColor,
        fontSize: fontSize + 'px',
        fontWeight: fontWeight,
      }"
    >
      {{ title }}
    </span>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: () => null,
  },
  icon: {
    type: String,
    default: () => null,
  },
  iconSize: {
    type: [Number, String],
    default: () => 18,
  },
  iconColor: {
    type: String,
    default: () => "#1A78FF",
  },
  fontColor: {
    type: String,
    default: () => "black",
  },
  fontSize: {
    type: [Number, String],
    default: () => 14,
  },
  fontWeight: {
    type: [Number, String],
    default: () => "normal",
  },
});

const ifImage = computed(() => {
  if (props.icon.trim().includes(".")) {
    return true;
  } else {
    return false;
  }
});
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  .icon {
    display: flex;
    align-items: center;
  }
  .title-text {
    padding-left: 8px;
  }
}
</style>
