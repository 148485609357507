<!-- 分组表格，支持单元格纵向合并 -->
<template>
  <!-- <div v-for="row in tableDataPaths" :key="row">{{ row }}</div> -->
  <!-- -----------------------------------
  <div>
    {{ rowspanMap }}
  </div> -->
  <native-table
    :table-titles="tableData.tableTitles"
    :span-method="arraySpanMethod"
    ref="tableRef"
    :rowData="tableDataPaths"
    :edit="editMap"
    :predict="predictMap"
    :show-header="ifShowHeader"
  ></native-table>
  <!-- <el-table
    :data="tableDataPaths"
    :span-method="oldarrayspanmethod"
    header-cell-class-name="header"
    :cell-class-name="tableRowClassName"
    :show-header="ifShowHeader"
  >
    <TableColumn
      v-for="titleItem in tableData.tableTitles"
      :key="titleItem.name"
      :col="titleItem"
      :edit="editMap"
      :predict="predictMap"
      :all-data="tableDataPaths"
    ></TableColumn>
  </el-table> -->
  <!-- <div class="note">{{ tableData.tableNote }}</div> -->
  <!-- {{ formattedTableData }}
  {{ rowspanMap }} -->
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  computed,
  defineExpose,
  provide,
} from "vue";
import { httpPost } from "@/api/httpService.js";

import TableColumn from "@/components/table/tableColumn.vue";
import NativeTable from "@/components/nativeTable/nativeTable.vue";
import { copyTableToClipboard } from "@/utils/copyToClipboard.js";

const props = defineProps({
  tableData: {
    type: Object,
    default: () => {},
  },
  ifShowHeader: {
    type: Boolean,
    default: true,
  },
});
// console.log("props", props.tableData);

//表格实例及note实例
const tableRef = ref(null);
const noteRef = ref(null);

// 用于记录每个单元格的rowspan的数量，通过rowspan来决定合并几行，格式为rowspan[prop][rowIndex]
const rowspanMap = ref({});
const colspanMap = ref({});
const bottonLineMap = ref({});
const editMap = ref({}); // 用于记录单元格是否支持编辑
const predictMap = ref({}); // 用于记录单元格是否支持预测

const tableColumns = ref([]);
// 生成rowspan
const generateSpanArray = (tableData, rowIndex) => {
  // 如果遇到叶节点则直接设置rownum为1
  if (!tableData?.childDataList?.length) {
    tableData.rowIndex = rowIndex;
    tableData.rowNum = 1;
    // for (let key in tableData.cellData) {
    //   // console.log("set rowspan", key, rowIndex, rowNum);
    //   if (!rowspanMap.value[key]) {
    //     rowspanMap.value[key] = [];
    //   }
    //   rowspanMap.value[key][rowIndex] = tableData.rowNum;
    // }
    Object.entries(tableData.cellData).forEach(([key, value]) => {
      if (!rowspanMap.value[key]) {
        rowspanMap.value[key] = [];
      }
      rowspanMap.value[key][rowIndex] = tableData.rowNum;

      if (!colspanMap.value[key]) {
        colspanMap.value[key] = [];
      }
      colspanMap.value[key][rowIndex] =
        value && value.colspan ? value.colspan : 1;

      if (!bottonLineMap.value[key]) {
        bottonLineMap.value[key] = [];
      }
      bottonLineMap.value[key][rowIndex] =
        value && value.botton_line ? value.botton_line : 0;

      // 是否可编辑
      if (!editMap.value[key]) {
        editMap.value[key] = [];
      }
      editMap.value[key][rowIndex] = value && value.edit ? value.edit : 0;

      // 是否为预测值
      if (!predictMap.value[key]) {
        predictMap.value[key] = [];
      }
      predictMap.value[key][rowIndex] =
        value && value.ifFunctionResult ? { ...value } : false;
    });

    return tableData;
  }

  let rowNum = 0; // 初始化单元格所占行数

  tableData.childDataList.forEach((child, childIndex) => {
    tableData.childDataList[childIndex] = generateSpanArray(
      child,
      childIndex == 0
        ? rowIndex
        : tableData.childDataList[childIndex - 1].rowIndex +
            tableData.childDataList[childIndex - 1].rowNum
    );
    rowNum += tableData.childDataList[childIndex].rowNum;
    // if (childIndex == 0) {
    //   _rowIndex = tableData.childDataList[childIndex].rowIndex;
    // }
  });
  tableData.rowNum = rowNum;
  tableData.rowIndex = rowIndex;
  // console.log("??", tableData);
  // for (let key in tableData.cellData) {
  //   // console.log("set rowspan", key, rowIndex, rowNum);
  //   if (!rowspanMap.value[key]) {
  //     rowspanMap.value[key] = [];
  //   }
  //   rowspanMap.value[key][rowIndex] = rowNum;
  // }
  Object.entries(tableData.cellData).forEach(([key, value]) => {
    if (!rowspanMap.value[key]) {
      rowspanMap.value[key] = [];
    }
    rowspanMap.value[key][rowIndex] = rowNum;

    if (!colspanMap.value[key]) {
      colspanMap.value[key] = [];
    }
    colspanMap.value[key][rowIndex] =
      value && value.colspan ? value.colspan : 1;

    if (!bottonLineMap.value[key]) {
      bottonLineMap.value[key] = [];
    }
    bottonLineMap.value[key][rowIndex] =
      value && value.botton_line ? value.botton_line : 0;

    if (!editMap.value[key]) {
      editMap.value[key] = [];
    }
    editMap.value[key][rowIndex] = value && value.edit ? value.edit : 0;
  });
  // console.log("rowspanmap", rowspanMap.value);
  return tableData;
};
const tableDataPaths = ref([]);
provide("allData", tableDataPaths); //完整表格数据

// dfs遍历原始表格数据，并将叶节点路径存入tableDataPaths;
const dfs = (node, path) => {
  if (!node) {
    return;
  }

  // path = { ...path, ...node.cellData };
  let pathNode = {};
  Object.entries(node.cellData).forEach(([key, value]) => {
    if (typeof value == "object" && value != null) {
      if (!value.value && value.edit) {
        pathNode[key] = "";
      } else {
        pathNode[key] = value.value;
      }
    } else {
      pathNode[key] = value;
    }
  });
  path = { ...path, ...pathNode };
  // console.log("dfs", node, path);
  if (node.childDataList?.length) {
    for (let child of node.childDataList) {
      dfs(child, path);
    }
  } else {
    tableDataPaths.value.push(path);
  }
};

// 初始化表格数据
const initTableData = (() => {
  let tableData = props.tableData.tableColumns;

  // test
  // let tableData = tableColumns.value;

  tableDataPaths.value = [];
  tableData.forEach((group, index) => {
    let formerGroup = tableData[index - 1];
    let rowIndex = index == 0 ? 0 : formerGroup.rowNum + formerGroup.rowIndex;
    group = generateSpanArray(group, rowIndex);
    // for (let key in group.cellData) {
    //   if (!rowspanMap.value[key]) {
    //     rowspanMap.value[key] = [];
    //   }
    //   console.log("init group", group);
    //   rowspanMap.value[key][formerGroup] = group.rowNum;
    // }

    dfs(group, {});
  });
  // console.log(
  //   "format tabledata",
  //   tableData,
  //   tableDataPaths.value,
  //   rowspanMap.value,
  //   colspanMap.value,
  //   editMap.value
  // );
})();

const oldarrayspanmethod = ({ row, column, rowIndex, columnIndex }) => {
  return {
    rowspan: rowspanMap.value[column.property]
      ? rowspanMap.value[column.property][rowIndex] || 0
      : 1,
    colspan: colspanMap.value[column.property]
      ? colspanMap.value[column.property][rowIndex]
        ? colspanMap.value[column.property][rowIndex]
        : 1
      : 1,
  };
};
// 表格组件合并单元格规则
const arraySpanMethod = ({ row, column, rowIndex, columnIndex }) => {
  let result = {
    rowspan: rowspanMap.value[column.value]
      ? rowspanMap.value[column.value][rowIndex] || 0
      : 0,
    colspan: colspanMap.value[column.value]
      ? colspanMap.value[column.value][rowIndex]
        ? colspanMap.value[column.value][rowIndex]
        : 0
      : 0,
  };

  return result;
};

const tableRowClassName = ({ row, column, rowIndex, columnIndex }) => {
  return bottonLineMap.value[column.property]
    ? bottonLineMap.value[column.property][rowIndex]
      ? "rowclass2"
      : "rowclass"
    : "rowclass";
};

const copyTable = () => {
  copyTableToClipboard(tableRef.value);
};

defineExpose({ tableRef, noteRef, copyTable });
</script>

<style lang="scss" scoped>
.el-table {
  border: 0px;
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
  background: #fff !important;

  :deep(.header) {
    // background: yellow;
    color: rgba(16, 16, 16, 1);
    border: 0px;
    border-bottom: 1px solid #000000 !important;
    background: #fff !important;
    user-select: auto;
  }
  :deep(.rowclass) {
    border: 0px;
    border-bottom: 0px solid #000000 !important;
    color: #000000;
    // background: red;
  }
  :deep(.rowclass2) {
    border: 0px;
    border-bottom: 1px solid #000000 !important;
    color: #000000;
    // background: red;
  }
}
.el-table--border::after {
  width: 0px;
  background: #fff;
}
.el-table--border::before {
  width: 0px;
  background: #fff;
}
:deep(.el-table__border-left-patch) {
  width: 0px;
  background: #fff;
}

.note {
  background: #fff;
  font-size: 14px;
}
</style>
