<template>
  <div class="analysis-result-item">
    <div class="analysis-result-item-chart">
      <div class="analysis-result-item-title">
        <AnalysisTitle
          :title="result.title"
          icon="icon-shuchujieguo"
          iconSize="24"
          iconColor="#1A78FF"
          fontWeight="bold"
        ></AnalysisTitle>
        <BaseTooltip v-if="result.titleInfo">
          <template #content>
            <div style="max-width: 300px" v-html="result.titleInfo"></div>
          </template>
          <i class="iconfont icon-wenhao-tishi info-message"></i>
        </BaseTooltip>
      </div>
      <div
        class="analysis-result-component-item"
        v-for="(item, index) in result.componentList"
        :key="index"
      >
        <component
          :is="currentComponent(item)"
          :data="item"
          id="componentAvoidSplit"
        ></component>
        <div class="analysis-result-text" v-if="item.intelligentAnalysis">
          <div class="analysis-result-text-title">
            <AnalysisTitle
              title="智能分析"
              icon="icon-zhinengfenxi"
              iconSize="24"
              iconColor="#1A78FF"
              fontWeight="bold"
            ></AnalysisTitle>
            <!-- TODO -->
            <el-button
              id="componentNotToPrint"
              type="primary"
              @click="copyTextToClipboard(item.intelligentAnalysis)"
              plain
            >
              <i class="iconfont icon-fuzhi-fenxijieguo"></i>
              复制
            </el-button>
          </div>
          <div
            class="analysis-result-text-text"
            v-html="item.intelligentAnalysis"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, getCurrentInstance, computed } from "vue";

import AnalysisTitle from "@/components/analysisResult/AnalysisTitle.vue";
import BaseTooltip from "@/components/base/BaseTooltip.vue";

import { ANALYSIS_CHART } from "@/constant/analysis/analysis_chart.js";
import { ANALYSIS_CHART_MAP } from "@/constant/analysis/analysis_chart.js";
import { copyTextToClipboard } from "@/utils/copyToClipboard.js";

const props = defineProps({
  result: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const currentComponent = computed(() => {
  return (item) => {
    return ANALYSIS_CHART_MAP[item.resultType]?.component || null;
  };
});
</script>

<style lang="scss" scoped>
.analysis-result-item {
  .analysis-result-item-chart {
    margin-bottom: 38px;
    .analysis-result-item-title {
      display: flex;
      font-weight: bold;
      height: 32px;
      i:first-child {
        padding-right: 8px;
      }
      .icon-wenhao-tishi {
        color: rgba(190, 190, 190, 1);
        padding-left: 8px;
        font-weight: normal;
      }
    }
  }

  .analysis-result-component-item {
    padding-top: 20px;
    .analysis-result-text {
      padding-top: 30px;
      i {
        padding-right: 8px;
      }
      .analysis-result-text-title {
        display: flex;
        justify-content: space-between;
        height: 32px;
      }
      .analysis-result-text-text {
        padding: 15px 32px 0px 32px;
      }
    }
  }
}
</style>
