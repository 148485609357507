<template>
  <div class="analysis-result" id="componentToPrint">
    <div class="analysis-result-header">
      <div class="analysis-result-title">
        <div>{{ tabInfo.name }}</div>
        <!-- TODO -->
        <div id="componentNotToPrint">
          <!-- <el-button
            type="primary"
            plain
            @click="exportToWord(tabInfo.name, 'componentToPrint')"
          >
            导出为Word
          </el-button> -->
          <el-button
            type="primary"
            plain
            @click="
              exportToPDF(
                tabInfo.name,
                'componentToPrint',
                'componentNotToPrint'
              )
            "
          >
            导出为PDF
          </el-button>
        </div>
      </div>
    </div>
    <div class="analysis-result-container">
      <div class="analysis-result-steps">
        <div>
          <AnalysisTitle
            title="分析步骤"
            icon="分析步骤图片.png"
            iconSize="32"
            fontColor="#F48F42"
            fontWeight="bold"
          ></AnalysisTitle>
        </div>
        <div class="analysis-result-content">
          <el-skeleton :loading="loadingVisible" animated>
            <template #template>
              <el-skeleton-item></el-skeleton-item>
              <el-skeleton-item></el-skeleton-item>
            </template>
            <template #default>
              <div v-html="analysisResult.analysisProcedure"></div>
            </template>
          </el-skeleton>
        </div>
      </div>
      <div class="analysis-result-data">
        <div>
          <AnalysisTitle
            title="详细结论"
            icon="详细结论图片.png"
            iconSize="32"
            fontColor="#F48F42"
            fontWeight="bold"
          ></AnalysisTitle>
        </div>
        <div class="analysis-result-content">
          <el-skeleton :loading="loadingVisible" animated>
            <template #template>
              <el-skeleton-item></el-skeleton-item>
              <el-skeleton-item></el-skeleton-item>
              <el-skeleton-item></el-skeleton-item>
              <el-skeleton-item></el-skeleton-item>
              <el-skeleton-item></el-skeleton-item>
            </template>
            <template #default>
              <div
                v-for="(item, index) in analysisResult.resultList"
                :key="index"
                class="analysis-result-content-item"
              >
                <AnalysisResultItem :result="item"></AnalysisResultItem>
              </div>
            </template>
          </el-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, getCurrentInstance, defineEmits } from "vue";
import AnalysisTitle from "@/components/analysisResult/AnalysisTitle.vue";
import AnalysisResultItem from "./AnalysisResultItem.vue";
import { httpPost } from "@/api/httpService.js";
const { proxy } = getCurrentInstance();
import { exportToPDF, exportToWord } from "@/utils/exportAnalysisResult.js";

const props = defineProps({
  id: {
    type: String,
    default: () => null,
  },
  tabInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const emits = defineEmits(["goToCondtion"]);

const loadingVisible = ref(true);

const analysisResult = ref({});
const getAnalysisResult = async () => {
  loadingVisible.value = true;
  let params = { id: props.tabInfo.analysisId };
  let res = await httpPost("/analysis/v1/getFormatResult", params);
  if (res.code == 0) {
    // 实际数据
    analysisResult.value = res.data;
    loadingVisible.value = false;

    // proxy.message.success("已生成频数分析结果！");
  } else {
    proxy.message.warning(res.message);
    emits("goToCondtion");
  }
};
getAnalysisResult();
</script>

<style lang="scss" scoped>
.analysis-result {
  .analysis-result-header {
    padding-top: 7px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e6e6;
    .analysis-result-title {
      line-height: 32px;
      height: 32px;
      font-weight: bold;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
    }
  }
  .analysis-result-container {
    .analysis-result-steps,
    .analysis-result-data {
      padding-top: 24px;
    }
    .analysis-result-data {
      padding-bottom: 30px;
    }

    .analysis-result-content {
      margin: 15px 40px 0 40px;
      line-height: 23px;
      .analysis-result-content-item {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
