<template>
  <!-- 散点图 -->
  <div ref="chartRef" class="chart"></div>
</template>

<script setup>
import * as echarts from "echarts";
import {
  onMounted,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  watch,
  computed,
} from "vue";

const props = defineProps({
  data: {
    type: Object,
    default: () => {
      return {
        // xAxisData: [],
        // series: [],
      };
    },
  },
  title: {
    type: Object,
    default: () => {
      return {
        title: "",
        xTitle: "",
        yTitle: "",
      };
    },
  },
  legendColor: {
    type: Object,
    default: () => {
      return [];
    },
  },
  // generate生成的原始配置
  rawOptions: {
    type: Object,
    default: () => {},
  },
});

const chartRef = ref(null);
let myChart;
let chartOption = ref({});
let defaultOption = {
  title: {
    text: "",
    left: "center",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
    },
  },
  legend: {
    type: "scroll",
    top: "10%",
  },
  grid: {
    top: "20%",
    containLabel: true,
  },
  xAxis: {
    type: "value",
    data: [],
    nameLocation: "middle",
    // nameGap: 30,
    // boundaryGap: ["20%", "20%"],
    // min: function (value) {
    //   return value.min - (value.max - value.min) * 0.2;
    // },
    // min: 2000,
  },
  yAxis: {
    type: "value",
    // min: function (value) {
    //   return Math.floor(Math.floor(value.min) / 10) * 10;
    // },
    // max: function (value) {
    //   //取最大值向上取整为最大刻度
    //   return Math.ceil(Math.ceil(value.max) / 10) * 10;
    // },
    nameLocation: "middle",
    nameGap: 40,
  },
  series: [],
};

const seriesItem = ref({
  name: null,
  data: [],
  type: "scatter",
  stack: null,
  itemStyle: {
    color: null,
  },
});

const chartData = ref(null);
const chartTitle = ref(null);
const chartLegendColor = ref(null);

onMounted(() => {
  initChartView();
});

// 初始化图片，使用父组件传入的值
const initChartView = () => {
  myChart = echarts.init(chartRef.value);
  chartOption.value = defaultOption;
  // 设置标题
  chartOption.value.title.text = chartTitle.value.title;
  if (chartOption.value.xAxis) {
    chartOption.value.xAxis.name = chartTitle.value.xTitle;
  }
  if (chartOption.value.yAxis) {
    chartOption.value.yAxis.name = chartTitle.value.yTitle;
  }
  // 设置x轴相关
  if (props.rawOptions.xAxisType) {
    chartOption.value.xAxis.type = props.rawOptions.xAxisType;
  }
  if (props.rawOptions.xDataList) {
    chartOption.value.xAxis.data = props.rawOptions.xDataList;
  }
  // 设置x轴缩放(默认开启缩放)
  if (props.rawOptions.ifZoomXData !== false) {
    chartOption.value.dataZoom = [
      {
        id: "dataZoomX",
        type: "slider",
        xAxisIndex: [0],
        filterMode: "filter",
      },
    ];
  }

  // 设置数据
  chartData.value.series.forEach((element, index) => {
    let newSeriesItem = JSON.parse(JSON.stringify(seriesItem.value));
    newSeriesItem = { ...newSeriesItem, ...element };
    chartOption.value.series.push(newSeriesItem);
  });

  // 设置图例颜色
  if (chartOption.value.series.length == 1) {
    chartOption.value.series[0].itemStyle.color = function (params) {
      let colorList = chartLegendColor.value;
      return colorList[0];
      // return colorList[params.dataIndex];
    };
  } else {
    chartOption.value.series = chartOption.value.series.map(
      (element, index) => {
        let newElement = JSON.parse(JSON.stringify(element));
        newElement.itemStyle.color = chartLegendColor.value[index];
        return newElement;
      }
    );
  }

  // 渲染
  if (myChart) {
    myChart.setOption(chartOption.value);
  }
  console.log("option", chartOption.value);
};

// 设置图数据
const setChartData = () => {
  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

// 设置图标题
const setChartTitle = () => {
  chartOption.value.title.text = chartTitle.value.title;
  if (chartOption.value.xAxis) {
    chartOption.value.xAxis.name = chartTitle.value.xTitle;
  }
  if (chartOption.value.yAxis) {
    chartOption.value.yAxis.name = chartTitle.value.yTitle;
  }
  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

// 设置图例
const setLegendColor = () => {
  if (chartOption.value.series.length == 1) {
    chartOption.value.series[0].itemStyle.color = function (params) {
      let colorList = chartLegendColor.value;
      return colorList[params.dataIndex];
    };
  } else {
    chartOption.value.series = chartOption.value.series.map(
      (element, index) => {
        let newElement = JSON.parse(JSON.stringify(element));
        newElement.itemStyle.color = chartLegendColor.value[index];
        return newElement;
      }
    );
  }

  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

// 图片导出
const exportChart = (type) => {
  let imgUrl = myChart.getDataURL({
    type: type,
    backgroundColor: "#fff",
    pixelRatio: 2,
  });
  var title = chartOption.value.title.text;
  var name =
    title == "" || title == null || title == undefined
      ? new Date().getTime()
      : title;
  if (window.navigator.msSaveOrOpenBlob) {
    var bstr = atob(imgUrl.split(",")[1]);
    var n = bstr.length;
    var u8arr = new Blob([u8arr]);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    if (type == "jpeg" || type == "jpg") {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".jpg");
    } else {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".png");
    }
  } else {
    const a = document.createElement("a");
    a.href = imgUrl;
    if (type == "jpeg" || type == "jpg") {
      a.setAttribute("download", name + ".jpg");
    } else {
      a.setAttribute("download", name + ".png");
    }
    a.click();
  }
};

watch(
  () => props.data,
  (newVal, oldVal) => {
    chartData.value = JSON.parse(JSON.stringify(props.data));
    if (myChart) {
      setChartData();
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.title,
  (newVal, oldVal) => {
    chartTitle.value = JSON.parse(JSON.stringify(props.title));
    if (myChart) {
      setChartTitle();
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.legendColor,
  (newVal, oldVal) => {
    chartLegendColor.value = JSON.parse(JSON.stringify(props.legendColor));
    if (myChart) {
      setLegendColor();
    }
  },
  { immediate: true, deep: true }
);

defineExpose({ exportChart });
</script>

<style lang="scss" scoped></style>
