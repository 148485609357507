// import { ANALYSIS_CHART } from "@/constant/analysis/analysis_chart.js";
import {
  analysisBarChart,
  analysisBarHorizontalChart,
  analysisPieChart,
  analysisHistogramChart,
  analysisPPQQChart,
  analysisScreeChart,
  analysisBoxChart,
  analysisScatterChart,
} from "@/utils/generateChartData.js";

import BarChart from "@/components/chart/BarChart.vue";
import BarHorizontalChart from "@/components/chart/BarHorizontalChart.vue";
import PieChart from "@/components/chart/PieChart.vue";
import ScatterChart from "@/components/chart/ScatterChart.vue";
import BoxChart from "@/components/chart/BoxChart.vue";
import HistogramChart from "@/components/chart/HistogramChart.vue";

import BarCollapse from "@/components/collapse/BarCollapse.vue";
import PieCollapse from "@/components/collapse/PieCollapse.vue";

const ANALYSIS_CHART = {
  // COMMON_TABLE: 1, //常规表格
  GROUP_TABLE: 2, //分组表格
  FREQUECY_CHART: 101, //频数统计图
  CROSS_FREQUECY_CHART: 102, //交叉频数统计图
  HISTOGRAM_CHART: 103, // 直方图
  PPQQ_CHART: 104, //P-P/Q-Q图
  SCREE_CHART: 105, //碎石图
  BOX_CHART: 106, // 箱线图
  SCATTER_CHART: 107, //散点图
  LINE_CHART: 108, //折线图
};

// CHART数据类型
const ANALYSIS_DATA_DRAW = {
  FREQUENCY: "frequency",
  PERCENTAGE: "percentage",
};

const ANALYSIS_DATA_DRAW_TYPE = {
  [ANALYSIS_DATA_DRAW.FREQUENCY]: { label: "频数" },
  [ANALYSIS_DATA_DRAW.PERCENTAGE]: { label: "百分比" },
};

const ANALYSIS_CHART_DRAW = {
  [ANALYSIS_CHART.FREQUECY_CHART]: ["1011", "1012", "1013", "1014"],
  [ANALYSIS_CHART.CROSS_FREQUECY_CHART]: ["1021", "1022", "1023", "1024"],
  [ANALYSIS_CHART.HISTOGRAM_CHART]: "1031",
  [ANALYSIS_CHART.PPQQ_CHART]: "1041",
  [ANALYSIS_CHART.SCREE_CHART]: "1051",
  [ANALYSIS_CHART.BOX_CHART]: "1061",
  [ANALYSIS_CHART.SCATTER_CHART]: "1071",
  [ANALYSIS_CHART.LINE_CHART]: "1081",
};

// chart组件
const ANALYSIS_DRAW_TYPE = {
  1011: {
    chartComponent: BarChart,
    collapseComponent: BarCollapse,
    label: "柱状图",
    generateChartData: analysisBarChart,
    stack: null,
    type: "bar",
  },
  1012: {
    chartComponent: BarHorizontalChart,
    collapseComponent: BarCollapse,
    label: "条形图",
    generateChartData: analysisBarHorizontalChart,
    stack: null,
  },
  1013: {
    chartComponent: PieChart,
    collapseComponent: PieCollapse,
    label: "饼状图",
    generateChartData: analysisPieChart,
    radius: "60%",
  },
  1014: {
    chartComponent: PieChart,
    collapseComponent: PieCollapse,
    label: "环形图",
    generateChartData: analysisPieChart,
    radius: ["30%", "60%"],
  },

  1021: {
    chartComponent: BarChart,
    collapseComponent: BarCollapse,
    label: "堆积柱状图",
    generateChartData: analysisBarChart,
    stack: "total",
    type: "bar",
  },
  1022: {
    chartComponent: BarChart,
    collapseComponent: BarCollapse,
    label: "分组柱状图",
    generateChartData: analysisBarChart,
    stack: null,
    type: "bar",
  },
  1023: {
    chartComponent: BarHorizontalChart,
    collapseComponent: BarCollapse,
    label: "堆积条形图",
    generateChartData: analysisBarHorizontalChart,
    stack: "total",
  },
  1024: {
    chartComponent: BarHorizontalChart,
    collapseComponent: BarCollapse,
    label: "分组条形图",
    generateChartData: analysisBarHorizontalChart,
    stack: null,
  },

  1031: {
    chartComponent: HistogramChart,
    collapseComponent: BarCollapse,
    label: "正态性检验直方图",
    generateChartData: analysisHistogramChart,
    stack: null,
    type: "line",
  },

  1041: {
    chartComponent: ScatterChart,
    collapseComponent: BarCollapse,
    label: "P-P/Q-Q图",
    generateChartData: analysisPPQQChart,
  },

  1051: {
    chartComponent: BarChart,
    collapseComponent: BarCollapse,
    label: "碎石图",
    generateChartData: analysisScreeChart,
    stack: null,
    type: "line",
  },

  1061: {
    chartComponent: BoxChart,
    collapseComponent: BarCollapse,
    label: "箱线图",
    generateChartData: analysisBoxChart,
  },

  1071: {
    chartComponent: ScatterChart,
    collapseComponent: BarCollapse,
    label: "散点图",
    generateChartData: analysisScatterChart,
    type: "scatter",
  },
  1081: {
    chartComponent: ScatterChart,
    collapseComponent: BarCollapse,
    label: "折线图",
    generateChartData: analysisScatterChart,
    type: "line",
  },
};

export {
  ANALYSIS_DRAW_TYPE,
  ANALYSIS_CHART_DRAW,
  ANALYSIS_DATA_DRAW,
  ANALYSIS_DATA_DRAW_TYPE,
};
