<template>
  <el-table-column :prop="col.value" :label="col.name" align="center">
    <template v-if="col.children != null">
      <tableColumn
        v-for="(item, key) in col.children"
        :key="key"
        :col="item"
        :edit="edit"
        :predict="predict"
      ></tableColumn>
    </template>
    <template v-if="!col.children" #default="scope">
      <!-- <span v-if="predict">{{ predict[column][rowIndex] }}</span> -->

      <template v-if="ifCanEdit(col.value, scope.$index)">
        <el-input
          v-model="scope.row[col.value]"
          placeholder="请输入您的预测值"
          type="number"
        ></el-input>
      </template>

      <template v-else-if="ifPredict(col.value, scope.$index)">
        {{ getPredictValue(col.value, scope.$index) }}
      </template>
      <template v-else>
        {{ scope.row[col.value] }}
      </template>
    </template>
  </el-table-column>
</template>

<script>
import { reactive, ref, defineProps, defineEmits, computed, inject } from "vue";
import TextEditor from "@/components/base/TextEditor.vue";
import { Debounce } from "@/utils/utils.js";

export default {
  name: "tableColumn",
  props: {
    col: {
      type: Object,
    },
    tableData: {
      tableColumns: {
        type: Array,
      },
      tableTitles: {
        type: Array,
      },
    },
    edit: {
      type: Object,
    },
    predict: {
      type: Object,
    },
  },
  components: {
    TextEditor,
  },

  setup(props) {
    const ifEditMap = ref({});
    const ifShowLoadingMap = ref({});
    const allData = inject("allData");
    const handleClickSaveListItemName = (text, row, scope, value) => {
      scope.row[value] = text;
      ifEditMap.value[row] = false;
      ifShowLoadingMap.value[row] = false;
    };
    const ifCanEdit = computed(() => (column, rowIndex) => {
      return props.edit
        ? props.edit[column]
          ? props.edit[column][rowIndex]
            ? true
            : false
          : false
        : false;
    });
    const ifPredict = computed(() => (column, rowIndex) => {
      return props.predict
        ? props.predict[column]
          ? props.predict[column][rowIndex]
            ? true
            : false
          : false
        : false;
    });
    const getPredictValue = computed(() => {
      return (column, rowIndex) => {
        console.log(column, rowIndex, props.predict[column][rowIndex]);
        let sum = 0;
        let { sumItemList } = props.predict[column][rowIndex];
        sumItemList.forEach((sumItem) => {
          // console.log(
          //   "sumitem",
          //   sumItem.factor,
          //   allData.value[sumItem.rowIndex][sumItem.colName] || 0
          // );
          sum +=
            sumItem.factor *
            (allData.value[sumItem.rowIndex][sumItem.colName] || 0);
        });

        // props.
        return sum.toFixed(3);
      };
    });
    return {
      ifEditMap,
      ifShowLoadingMap,
      handleClickSaveListItemName,
      ifCanEdit,
      ifPredict,
      getPredictValue,
      allData,
    };
  },
  mounted() {},
};
</script>

<style scoped></style>
