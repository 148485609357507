<template>
  <div class="analysis-side-menu-wrapper">
    <div class="search-bar">
      <div>
        <el-input
          v-model="searchValue"
          placeholder="搜索方法"
          :prefix-icon="Search"
          clearable
        />
      </div>
    </div>
    <div class="group-container">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item
          v-for="analysisGroup in setAnalysisGroupList"
          :key="analysisGroup.groupId"
          :name="analysisGroup.groupId"
        >
          <template #title>
            <div class="group-title-bar">
              <AnalysisTitle
                :title="analysisGroup.title"
                :icon="analysisGroup.icon"
                fontSize="15"
              ></AnalysisTitle>
            </div>
          </template>
          <div class="group-item">
            <el-button
              class="analsis-item"
              v-for="analysisItem in analysisGroup.children"
              :key="analysisItem.name"
              @click="handleClickAnalysis(analysisItem)"
            >
              {{ analysisItem.name }}
              <div class="analsis-item-add">
                <i class="iconfont icon-jiahao"></i>
              </div>
            </el-button>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div
        v-if="setAnalysisGroupList.length == 0"
        class="analysis-groupList-empty"
      >
        暂无结果
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, computed, watch } from "vue";
import { Search } from "@element-plus/icons-vue";

import { httpPost } from "@/api/httpService.js";
import { ANALYSIS_SIDE_LIST } from "@/constant/analysis/analysis_type.js";

import AnalysisTitle from "@/components/analysisResult/AnalysisTitle.vue";
import { ElMessage } from "element-plus";

const emits = defineEmits(["clickItem"]);

const handleClickAnalysis = (analysis) => {
  if (analysis.disabled) {
    ElMessage.warning("功能升级中 敬请期待");
    return;
  }
  emits("clickItem", analysis);
};

const activeNames = ref(["1", "2", "3", "4"]);
const handleChange = (val) => {};

// 搜索
const searchValue = ref("");

const setAnalysisGroupList = computed(() => {
  let analysisGroupList = [];
  if (searchValue.value) {
    ANALYSIS_SIDE_LIST.forEach((element) => {
      let analysisGroup = { ...element, children: [] };
      element.children.forEach((child) => {
        if (child.name.includes(searchValue.value)) {
          analysisGroup.children.push(child);
        }
      });
      if (analysisGroup.children.length != 0) {
        analysisGroupList.push(analysisGroup);
      }
    });
  } else {
    analysisGroupList = ANALYSIS_SIDE_LIST;
  }
  return analysisGroupList;
});

watch(
  () => setAnalysisGroupList,
  (newVal, oldVal) => {
    if (newVal) {
      activeNames.value = [];
      newVal.value.forEach((element) => {
        activeNames.value.push(element.groupId);
      });
    }
  },
  { immediate: true, deep: true }
);
</script>

<style lang="scss" scoped>
.analysis-side-menu-wrapper {
  position: relative;
  height: calc(100%);
  .search-bar {
    position: relative;
    height: 70px;
    width: 270px;
    justify-content: center;
    padding: 20px;
    z-index: 1;
    div {
      width: 230px;
    }
  }
  .group-container {
    height: calc(100% - 70px);
    position: relative;
    overflow: auto;
    :deep(.el-collapse) {
      border: 1px solid transparent;
    }
    .group-title-bar {
      padding-left: 20px;
      position: relative;
      top: 2px;
    }
    :deep(.el-collapse-item__header) {
      border-bottom: 1px solid transparent;
      height: 40px;
    }
    :deep(.el-collapse-item__wrap) {
      border-bottom: 1px solid transparent;
    }
    :deep(.el-collapse-item__content) {
      padding-bottom: 0px;
    }
    :deep(.el-collapse-item__arrow) {
      margin: 4px auto 0 8px;
    }
    .group-item {
      position: relative;
      padding: 10px 20px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 110px);
      grid-gap: 10px;
      :deep(.el-button) {
        margin-left: 0;
      }
      .analsis-item:hover,
      .analsis-item:focus {
        border: 1px solid rgba(26, 120, 255, 1);
        .analsis-item-add {
          display: inline-block;
        }
      }
      .analsis-item {
        position: relative;
        .analsis-item-add {
          display: none;
          position: absolute;
          width: 14px;
          height: 14px;
          color: $theme-color;
          right: 8px;
          background-color: #e8f2ff;
          border-radius: 1px;
          .iconfont {
            font-size: 10px;
          }
        }
      }
    }
    .analysis-groupList-empty {
      text-align: center;
      margin: 30px auto;
      color: rgba(154, 154, 154, 1);
    }
  }
}
</style>
