import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import docxtemplater from "docxtemplater";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import PizZip from "pizzip";

const exportToPDF = async (pdfName, printId, notPrintId) => {
  const ele = document.querySelector(`#${printId}`);
  // 创建一个DOM 元素，包含要导出的内容
  // const ele = document.createElement("div");
  if (ele) {
    // 如果采用cloneNode方案，图表无法克隆
    // ele.appendChild(printEle.cloneNode(true));
    // ele.style.padding = "30px";
    // 将元素插入到文档中
    // document.body.appendChild(ele);
    // 隐藏不需要显示在pdf的元素
    const notPrintEles = ele.querySelectorAll(`#${notPrintId}`);
    notPrintEles.forEach((element) => {
      element.style.display = "none";
    });
  }

  //滚动到页面顶部
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  let eleW = ele.offsetWidth; // 获得该容器的宽
  let eleH = ele.offsetHeight; // 获得该容器的高
  let eleOffsetTop = ele.offsetTop; // 获得该容器到文档顶部的距离
  let eleOffsetLeft = ele.offsetLeft; // 获得该容器到文档最左的距离
  let canvas = document.createElement("canvas");
  let abs = 0;

  let win_in =
    document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
  let win_out = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）

  if (win_out > win_in) {
    abs = (win_out - win_in) / 2; // 获得滚动条宽度的一半
  }
  canvas.width = eleW * 2; // 将画布宽&&高放大两倍
  canvas.height = eleH * 2;

  let context = canvas.getContext("2d"); //二维绘图
  context.scale(2, 2); //宽高扩大两倍
  context.translate(-eleOffsetLeft - abs, -eleOffsetTop);
  // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
  // translate的时候，要把这个差值去掉

  await html2canvas(ele, {
    // dpi: 300,
    allowTaint: true, //允许 canvas 污染， allowTaint参数要去掉，否则是无法通过toDataURL导出canvas数据的
    // useCORS: true, //允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
  }).then(async (canvas) => {
    //a4纸的尺寸
    const a4Width = 595.28;
    const a4Height = 841.89;
    let contentWidth = canvas.width;
    let contentHeight = canvas.height;
    //一页pdf显示html页面生成的canvas高度;
    let pageHeight = (contentWidth / a4Width) * a4Height;
    //未生成pdf的html页面高度
    let leftHeight = contentHeight;
    //页面偏移
    let position = 0;
    //html页面生成的canvas在pdf中图片的宽高
    let imgWidth = a4Width;
    let imgHeight = (a4Width / contentWidth) * contentHeight;
    let pageData = canvas.toDataURL("image/jpeg", 1.0);
    let pdf = new jsPDF("", "pt", "a4");

    //有两个高度需要区分，一个是html页面的实际高度，和 生成pdf的页面高度
    //当内容未超过pdf一页显示的范围，无需分页
    if (leftHeight < pageHeight) {
      //pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示
      pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
    } else {
      //获取不可被分页分割的元素
      let avoidSplitEles = ele.querySelectorAll("#componentAvoidSplit");
      const checkElementSplit = (element, pagePosition) => {
        const elementTop = element.offsetTop;
        const elementBottom = elementTop + element.offsetHeight;
        const pageBottom = pagePosition;
        return elementTop < pageBottom && elementBottom > pageBottom;
      };

      // 分页
      let currentPage = 1;
      let currentPagePosition = a4Height;
      let adjustedPosition = a4Height;
      while (leftHeight > 0) {
        let splitDetected = false;
        let splitElementHeight = 0;
        let splitElementTop = 0;
        let splitElement = null;

        avoidSplitEles.forEach((element) => {
          if (checkElementSplit(element, currentPagePosition)) {
            splitDetected = true;
            splitElementHeight = element.offsetHeight;
            splitElementTop = element.offsetTop;
            splitElement = element;
            // element.style.marginTop = `${
            //   currentPagePosition - splitElementTop
            // }px`;
          }
        });

        // if (splitDetected) {
        // splitElement.style.display = "none";
        // adjustedPosition = position + (splitElementTop - currentPagePosition);
        // pdf.addImage(
        //   pageData,
        //   "JPEG",
        //   0,
        //   position,
        //   imgWidth,
        //   adjustedPosition
        // );
        // leftHeight -= adjustedPosition;
        // position -= adjustedPosition;
        // currentPage++;
        // currentPagePosition = currentPage * a4Height;
        // pdf.addPage();
        // pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight); //正确移动的后一页
        // position -= a4Height - adjustedPosition;
        // leftHeight -= pageHeight - adjustedPosition;
        // } else {
        pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
        leftHeight -= pageHeight;
        position -= a4Height;
        if (leftHeight > 0) {
          currentPage++;
          currentPagePosition = currentPage * a4Height;
          pdf.addPage();
        }
        // }
      }
    }

    pdf.save(pdfName);
    //将元素从文档中移除
    // document.body.removeChild(ele);
    //复原样式
    // ele.style.padding = "0px";
    const notPrintEles = ele.querySelectorAll(`#${notPrintId}`);
    notPrintEles.forEach((element) => {
      element.style.display = "";
    });
    // let datauri = pdf.output("dataurlstring");
    // let base64 = datauri.split("base64,")[1];
    // let data = {
    //   data: base64,
    // };
    // let key =
    //   "WCP" +
    //   new Date().getTime() +
    //   sessionStorage.getItem("");
    // let params = {
    //   key: key,
    //   data: JSON.stringify(data),
    // };
    // await httpPost("/attachment/v1/save/redis", params)
    //   .then((res) => {
    //     if (res.code == 0) {
    //       sessionStorage.setItem("", key);
    //     }
    //   })
    //   .catch(() => {
    //     Message({
    //       message: "生成pdf失败，请联系管理员",
    //       type: "error",
    //     });
    //   });
  });
};

// const exportToPDF = async (pdfName, printId, notPrintId) => {
//   const ele = document.querySelector(`#${printId}`);
//   // 创建一个DOM 元素，包含要导出的内容
//   // const ele = document.createElement("div");
//   if (ele) {
//     // 如果采用cloneNode方案，图表无法克隆
//     // ele.appendChild(printEle.cloneNode(true));
//     // ele.style.padding = "30px";
//     // 将元素插入到文档中
//     // document.body.appendChild(ele);
//     // 隐藏不需要显示在pdf的元素
//     const notPrintEles = ele.querySelectorAll(`#${notPrintId}`);
//     notPrintEles.forEach((element) => {
//       element.style.display = "none";
//     });
//   }

//   //滚动到页面顶部
//   document.body.scrollTop = document.documentElement.scrollTop = 0;
//   let eleW = ele.offsetWidth; // 获得该容器的宽
//   let eleH = ele.offsetHeight; // 获得该容器的高
//   let eleOffsetTop = ele.offsetTop; // 获得该容器到文档顶部的距离
//   let eleOffsetLeft = ele.offsetLeft; // 获得该容器到文档最左的距离
//   let canvas = document.createElement("canvas");
//   let abs = 0;

//   let win_in =
//     document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
//   let win_out = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）

//   if (win_out > win_in) {
//     abs = (win_out - win_in) / 2; // 获得滚动条宽度的一半
//   }
//   canvas.width = eleW * 2; // 将画布宽&&高放大两倍
//   canvas.height = eleH * 2;

//   let context = canvas.getContext("2d"); //二维绘图
//   context.scale(2, 2); //宽高扩大两倍
//   context.translate(-eleOffsetLeft - abs, -eleOffsetTop);
//   // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
//   // translate的时候，要把这个差值去掉

//   await html2canvas(ele, {
//     // dpi: 300,
//     allowTaint: true, //允许 canvas 污染， allowTaint参数要去掉，否则是无法通过toDataURL导出canvas数据的
//     // useCORS: true, //允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
//   }).then(async (canvas) => {
//     //a4纸的尺寸
//     const a4Width = 595.28;
//     // const a4Height = 841.89;
//     let contentWidth = canvas.width;
//     let contentHeight = canvas.height;
//     //一页pdf显示html页面生成的canvas高度;
//     let pageHeight = (a4Width / contentWidth) * contentHeight;
//     //未生成pdf的html页面高度
//     let leftHeight = contentHeight;
//     //页面偏移
//     let position = 0;
//     //html页面生成的canvas在pdf中图片的宽高
//     let imgWidth = a4Width;
//     let imgHeight = (a4Width / contentWidth) * contentHeight;
//     let pageData = canvas.toDataURL("image/jpeg", 1.0);
//     let pdf = new jsPDF("", "pt", "a4");

//     //有两个高度需要区分，一个是html页面的实际高度，和 生成pdf的页面高度
//     //当内容未超过pdf一页显示的范围，无需分页
//     if (leftHeight < pageHeight) {
//       //pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示
//       pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
//     } else {
//       while (leftHeight > 0) {
//         pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
//         leftHeight -= pageHeight;
//         position -= imgHeight;
//         if (leftHeight > 0) {
//           pdf.addPage();
//         }
//       }
//     }

//     pdf.save(pdfName);
//     //将元素从文档中移除
//     // document.body.removeChild(ele);
//     //复原样式
//     // ele.style.padding = "0px";
//     const notPrintEles = ele.querySelectorAll(`#${notPrintId}`);
//     notPrintEles.forEach((element) => {
//       element.style.display = "";
//     });
//     // let datauri = pdf.output("dataurlstring");
//     // let base64 = datauri.split("base64,")[1];
//     // let data = {
//     //   data: base64,
//     // };
//     // let key =
//     //   "WCP" +
//     //   new Date().getTime() +
//     //   sessionStorage.getItem("");
//     // let params = {
//     //   key: key,
//     //   data: JSON.stringify(data),
//     // };
//     // await httpPost("/attachment/v1/save/redis", params)
//     //   .then((res) => {
//     //     if (res.code == 0) {
//     //       sessionStorage.setItem("", key);
//     //     }
//     //   })
//     //   .catch(() => {
//     //     Message({
//     //       message: "生成pdf失败，请联系管理员",
//     //       type: "error",
//     //     });
//     //   });
//   });
// };

const exportToWord = (wordName, printId) => {};

const exportTableToImage = (ref) => {
  const table = ref.tableRef.$el;
  html2canvas(table).then((canvas) => {
    const imageData = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = imageData;
    link.download = "exported_image.png";
    link.click();
    // const newWindow = window.open();
    // newWindow.document.write(`<img src="${imageData}" />`);
  });
};

const exportTableToExcel = (ref) => {
  // const tableData = this.$refs.table.data;
  const tableData = ref.tableRef.$el;
  const worksheet = XLSX.utils.table_to_sheet(tableData, { raw: true });

  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, { type: "array", bookType: "xlsx" });
  saveAsExcel(excelBuffer, "exported_table.xlsx");
};

const saveAsExcel = (buffer, fileName) => {
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

export { exportToPDF, exportToWord, exportTableToImage, exportTableToExcel };
