<template>
  <div class="analysis-result-chart">
    <div class="analysis-result-chart-select" id="componentNotToPrint"></div>
    <div class="analysis-result-chart-view">
      <div class="analysis-result-chart-content">
        <component
          ref="chartRef"
          :is="currentChartComponent"
          :data="analysisChartData.data"
          :title="analysisChartData.title"
          :legendColor="analysisChartData.legendColor"
          :type="analysisChartData.type"
          :rawOptions="analysisChartData"
        ></component>
      </div>
      <div class="analysis-result-chart-option" id="componentNotToPrint">
        <div class="analysis-result-chart-option-title">样式</div>
        <component
          ref="collapseRef"
          :is="currentCollapseComponent"
          :title="analysisChartData.title"
          :legendColorOption="analysisChartData.legendColorOption"
          @saveImage="saveImage"
          @handleChangeLegendColor="handleChangeLegendColor"
          @handleChangeTitle="handleChangeTitle"
        ></component>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, watch, toRef, watchEffect } from "vue";

import {
  ANALYSIS_DRAW_TYPE,
  ANALYSIS_CHART_DRAW,
  ANALYSIS_DATA_DRAW,
  ANALYSIS_DATA_DRAW_TYPE,
} from "@/constant/analysis/analysis_draw.js";

const props = defineProps({
  // 画图数据
  data: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

// 子组件引用
const chartRef = ref(null);
const collapseRef = ref(null);

// 监听传入data，提取数据
const chartData = ref(null);
const analysisChartData = ref(null);
watch(
  () => props.data,
  (newVal, oldVal) => {
    chartData.value = JSON.parse(JSON.stringify(props.data));
    // test
    // 直方图
    // chartData.value = {
    //   intelligentAnalysis: null,
    //   resultData: {
    //     title: "annualIncome正态性检验直方图",
    //     histogramData: [
    //       {
    //         fromValue: 1,
    //         toValue: 2,
    //         frequency: 6,
    //       },
    //       {
    //         fromValue: 2,
    //         toValue: 3,
    //         frequency: 10,
    //       },
    //       {
    //         fromValue: 3,
    //         toValue: 4,
    //         frequency: 18,
    //       },
    //       {
    //         fromValue: 4,
    //         toValue: 5,
    //         frequency: 10,
    //       },
    //     ],
    //     normalDistributionData: [
    //       {
    //         xaxisData: 10,
    //         yaxisData: 0.0163,
    //       },
    //       {
    //         xaxisData: 10.3968,
    //         yaxisData: 0.0182,
    //       },
    //     ],
    //   },
    //   resultType: 103,
    // };
    // ppqq
    // chartData.value = {
    //   intelligentAnalysis: null,
    //   resultData: {
    //     title: "收入",
    //     data: [
    //       {
    //         actualValue: 1,
    //         predictValue: 1.1,
    //       },
    //       {
    //         actualValue: 2,
    //         predictValue: 2.1,
    //       },
    //       {
    //         actualValue: 3,
    //         predictValue: 3.1,
    //       },
    //     ],
    //     //...
    //   },
    //   resultType: 104,
    // };
    // 碎石图
    // chartData.value = {
    //   intelligentAnalysis: null,
    //   resultData: {
    //     title: "收入",
    //     data: [
    //       //
    //       {
    //         chartVariableName: 1,
    //         eigenValue: 9.33,
    //       },
    //       {
    //         chartVariableName: 2,
    //         eigenValue: 6.44,
    //       },
    //       {
    //         chartVariableName: 3,
    //         eigenValue: 2.11,
    //       },
    //       {
    //         chartVariableName: 4,
    //         eigenValue: 2.11,
    //       },
    //     ],
    //   },
    //   resultType: 105,
    // };
    // 箱线图
    // chartData.value = {
    //   intelligentAnalysis: null,
    //   resultData: {
    //     title: "收入",
    //     data: [
    //       {
    //         chartVariableName: "分类1",
    //         valueList: [5, 95, 100, 102, 103, 105],
    //       },
    //       {
    //         chartVariableName: "分类2",
    //         valueList: [99, 104, 105, 106],
    //       },
    //       {
    //         chartVariableName: "分类3",
    //         valueList: [10, 107, 108, 109, 110],
    //       },
    //       {
    //         chartVariableName: "分类4",
    //         valueList: [107, 108, 109, 110, 111],
    //       },
    //     ],
    //   },
    //   resultType: 106,
    // };
    // 散点图
    // chartData.value = {
    //   intelligentAnalysis: null,
    //   resultData: {
    //     title: "收入",
    //     data: [
    //       {
    //         chartVariableName: "收入1",
    //         childDataList: [
    //           {
    //             xValue: 1,
    //             yValue: 1.1,
    //           },
    //           {
    //             xValue: 2,
    //             yValue: 2.1,
    //           },
    //           {
    //             xValue: 3,
    //             yValue: 2.1,
    //           },
    //         ],
    //       },
    //       {
    //         chartVariableName: "收入2",
    //         childDataList: [
    //           {
    //             xValue: 1,
    //             yValue: 4,
    //           },
    //           {
    //             xValue: 2,
    //             yValue: 6,
    //           },
    //           {
    //             xValue: 3,
    //             yValue: 4,
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   resultType: 107,
    // };

    chartData.value.resultType =
      ANALYSIS_CHART_DRAW[chartData.value.resultType];
    analysisChartData.value = ANALYSIS_DRAW_TYPE[
      chartData.value.resultType
    ].generateChartData(chartData.value);
  },
  { immediate: true, deep: true }
);

// 设置图类型
const currentChartComponent = computed(() => {
  return ANALYSIS_DRAW_TYPE[chartData.value.resultType].chartComponent || null;
});
const currentCollapseComponent = computed(() => {
  return (
    ANALYSIS_DRAW_TYPE[chartData.value.resultType].collapseComponent || null
  );
});

// 图片样式修改
const handleChangeTitle = (newTitle) => {
  analysisChartData.value.title = newTitle;
};
const handleChangeLegendColor = (legendColor) => {
  analysisChartData.value.legendColor = legendColor;
};
const saveImage = (type) => {
  chartRef.value.exportChart(type);
};
</script>

<style lang="scss" scoped>
.analysis-result-chart {
  .analysis-result-chart-select {
    display: flex;
    justify-content: right;
    .analysis-result-chart-select-item {
      padding-left: 20px;
      width: 140px;
    }
  }
  .analysis-result-chart-view {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    .analysis-result-chart-content {
      height: 500px;
      width: 75%;
      display: flex;
      justify-content: center;
      .chart {
        height: 500px;
        width: 70%;
        display: flex;
        justify-content: center;
      }
    }
    .analysis-result-chart-option {
      width: 200px;
      .analysis-result-chart-option-title {
        line-height: 48px;
        font-weight: bold;
      }
      :deep(.el-collapse-item__header) {
        font-weight: normal;
      }
      .analysis-result-chart-option-button {
        display: grid;
        grid-template-columns: repeat(2, 45%);
        grid-gap: 10px;
        :deep(.el-button) {
          margin-left: 0;
        }
      }
      .legend-color {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .el-select {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
