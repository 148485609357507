<template>
  <div class="tab-bar">
    <!-- {{ modelValue }} -->
    <el-tag
      class="tab-item"
      size="default"
      :closable="!tabItem.unclosable"
      v-for="tabItem in tabList"
      :key="tabItem.name"
      :type="modelValue == tabItem.id ? '' : 'info'"
      @click="handleClickTab(tabItem)"
      @close="handleCloseTab(tabItem)"
    >
      {{ tabItem.name }}
      <!-- {{ tabItem.id }} -->
    </el-tag>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits } from "vue";
import { httpPost } from "@/api/httpService.js";

const props = defineProps({
  //当前页
  modelValue: {
    type: [String, Number],
  },
  // 标签页tab列表
  tabList: {
    type: Array,
    default: () => {
      return [];
    },
  },
});

const emits = defineEmits(["update:modelValue", "change", "close"]);

const handleClickTab = (tab) => {
  emits("update:modelValue", tab.id);
  emits("change", tab);
};
const handleCloseTab = (tab) => {
  emits("close", tab);
};
</script>

<style lang="scss" scoped>
.tab-bar {
  width: 100%;
  max-width: 100%;
  height: $tab-bar-height;
  padding: 6px;
  // line-height: $tab-bar-height;
  background: $main-page-background;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: $analysis-layout-padding;

  .tab-item {
    cursor: pointer;
  }
  .tab-item + .tab-item {
    margin-left: 12px;
  }
}
</style>
