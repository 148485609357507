<template>
  <!-- 散点图 -->
  <div ref="chartRef" class="chart"></div>
</template>

<script setup>
import * as echarts from "echarts";
import {
  onMounted,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  watch,
  computed,
} from "vue";

const props = defineProps({
  data: {
    type: Object,
    default: () => {
      return {
        xAxisData: [],
        series: [],
      };
    },
  },
  title: {
    type: Object,
    default: () => {
      return {
        title: "",
        xTitle: "",
        yTitle: "",
      };
    },
  },
  legendColor: {
    type: Object,
    default: () => {
      return [];
    },
  },
});

const chartRef = ref(null);
let myChart;
let chartOption = ref({});
let defaultOption = {
  title: {
    text: "",
    left: "center",
  },
  dataset: [
    {
      // prettier-ignore
      // source: [
      //             [850, 740, 900, 1070, 930, 850, 950, 980, 980, 880, 1000, 980, 930, 650, 760, 810, 1000, 1000, 960, 960],
      //             [960, 940, 960, 940, 880, 800, 850, 880, 900, 840, 830, 790, 810, 880, 880, 830, 800, 790, 760, 800],
      //             [880, 880, 880, 860, 720, 720, 620, 860, 970, 950, 880, 910, 850, 870, 840, 840, 850, 840, 840, 840],
      //             [890, 810, 810, 820, 800, 770, 760, 740, 750, 760, 910, 920, 890, 860, 880, 720, 840, 850, 850, 780],
      //             [890, 840, 780, 810, 760, 810, 790, 810, 820, 850, 870, 870, 810, 740, 810, 940, 950, 800, 810, 870]
      //         ],
      source: [],
    },
    {
      transform: {
        type: "boxplot",
        config: {
          itemNameFormatter: function (params) {
            return params.value;
          },
        },
      },
    },
    {
      fromDatasetIndex: 1,
      fromTransformResult: 1,
    },
  ],
  tooltip: {
    trigger: "item",
    axisPointer: {
      type: "shadow",
    },
  },
  grid: {
    top: "20%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    nameLocation: "middle",
    nameGap: 30,
    splitArea: {
      show: false,
    },
    splitLine: {
      show: false,
    },
    data: [],
  },
  // legend: {
  //   top: "10%",
  // },
  yAxis: {
    type: "value",
    nameLocation: "middle",
    nameGap: 30,
    splitArea: {
      show: true,
    },
  },
  series: [],
};

const chartData = ref(null);
const chartTitle = ref(null);
const chartLegendColor = ref(null);

onMounted(() => {
  initChartView();
});

// 初始化图片，使用父组件传入的值
const initChartView = () => {
  myChart = echarts.init(chartRef.value);
  chartOption.value = defaultOption;
  // 设置标题
  chartOption.value.title.text = chartTitle.value.title;
  if (chartOption.value.xAxis) {
    chartOption.value.xAxis.name = chartTitle.value.xTitle;
  }
  if (chartOption.value.yAxis) {
    chartOption.value.yAxis.name = chartTitle.value.yTitle;
  }
  // 设置数据
  chartOption.value.xAxis.data = chartData.value.xAxisData;
  chartOption.value.dataset[0].source = chartData.value.dataset[0].source;
  chartOption.value.series = JSON.parse(JSON.stringify(chartData.value.series));
  // 设置图例颜色
  if (chartOption.value.series.length == 1) {
    chartOption.value.series[0].itemStyle.color = function (params) {
      let colorList = chartLegendColor.value;
      return colorList[params.dataIndex];
    };
  } else {
    chartOption.value.series = chartOption.value.series.map(
      (element, index) => {
        let newElement = JSON.parse(JSON.stringify(element));
        // 非异常值形况下视为箱线图
        if (element.type != "scatter") {
          newElement.itemStyle.color = chartLegendColor.value[index];
        } else {
          // 散点情况显示异常点（红色）
          let warningColor = "#E94743";
          newElement.itemStyle = {
            color: warningColor,
          };
        }

        return newElement;
      }
    );
  }
  // 渲染
  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

// 设置图数据
const setChartData = () => {
  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

// 设置图标题
const setChartTitle = () => {
  chartOption.value.title.text = chartTitle.value.title;
  if (chartOption.value.xAxis) {
    chartOption.value.xAxis.name = chartTitle.value.xTitle;
  }
  if (chartOption.value.yAxis) {
    chartOption.value.yAxis.name = chartTitle.value.yTitle;
  }
  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

// 设置图例
const setLegendColor = () => {
  if (chartOption.value.series.length == 1) {
    chartOption.value.series[0].itemStyle.color = function (params) {
      let colorList = chartLegendColor.value;
      return colorList[params.dataIndex];
    };
  } else {
    chartOption.value.series = chartOption.value.series.map(
      (element, index) => {
        let newElement = JSON.parse(JSON.stringify(element));
        newElement.itemStyle.color = chartLegendColor.value[index];
        return newElement;
      }
    );
  }

  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

// 图片导出
const exportChart = (type) => {
  let imgUrl = myChart.getDataURL({
    type: type,
    backgroundColor: "#fff",
    pixelRatio: 2,
  });
  var title = chartOption.value.title.text;
  var name =
    title == "" || title == null || title == undefined
      ? new Date().getTime()
      : title;
  if (window.navigator.msSaveOrOpenBlob) {
    var bstr = atob(imgUrl.split(",")[1]);
    var n = bstr.length;
    var u8arr = new Blob([u8arr]);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    if (type == "jpeg" || type == "jpg") {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".jpg");
    } else {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".png");
    }
  } else {
    const a = document.createElement("a");
    a.href = imgUrl;
    if (type == "jpeg" || type == "jpg") {
      a.setAttribute("download", name + ".jpg");
    } else {
      a.setAttribute("download", name + ".png");
    }
    a.click();
  }
};

watch(
  () => props.data,
  (newVal, oldVal) => {
    chartData.value = JSON.parse(JSON.stringify(props.data));
    if (myChart) {
      setChartData();
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.title,
  (newVal, oldVal) => {
    chartTitle.value = JSON.parse(JSON.stringify(props.title));
    if (myChart) {
      setChartTitle();
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.legendColor,
  (newVal, oldVal) => {
    chartLegendColor.value = JSON.parse(JSON.stringify(props.legendColor));
    if (myChart) {
      setLegendColor();
    }
  },
  { immediate: true, deep: true }
);

defineExpose({ exportChart });
</script>

<style lang="scss" scoped></style>
