<template>
  <div class="analysis-result-chart">
    <div class="analysis-result-chart-select" id="componentNotToPrint">
      <el-select
        v-model="dataType"
        placeholder="请选择"
        class="analysis-result-chart-select-item"
        @change="handleChangeDataType"
      >
        <el-option
          v-for="item in dataTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select
        v-model="selectedView"
        placeholder="Select"
        class="analysis-result-chart-select-item"
        @change="handleChangeView"
      >
        <el-option
          v-for="item in viewOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="analysis-result-chart-view">
      <div class="analysis-result-chart-content">
        <component
          ref="chartRef"
          :is="currentChartComponent"
          :data="analysisChartData.data"
          :title="analysisChartData.title"
          :legendColor="analysisChartData.legendColor"
        ></component>
      </div>
      <div class="analysis-result-chart-option" id="componentNotToPrint">
        <div class="analysis-result-chart-option-title">样式</div>
        <component
          ref="collapseRef"
          :is="currentCollapseComponent"
          :title="analysisChartData.title"
          :legendColorOption="analysisChartData.legendColorOption"
          @saveImage="saveImage"
          @handleChangeLegendColor="handleChangeLegendColor"
          @handleChangeTitle="handleChangeTitle"
        ></component>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, watch, toRef, watchEffect } from "vue";

import {
  ANALYSIS_DRAW_TYPE,
  ANALYSIS_CHART_DRAW,
  ANALYSIS_DATA_DRAW,
  ANALYSIS_DATA_DRAW_TYPE,
} from "@/constant/analysis/analysis_draw.js";

const props = defineProps({
  // 画图数据
  data: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

// 子组件引用
const chartRef = ref(null);
const collapseRef = ref(null);

// 数据样式选择、图类型选择
const selectDataType = ref("");
const selectedView = ref("");
const dataTypeOptions = ref([]);
dataTypeOptions.value = Object.entries(ANALYSIS_DATA_DRAW_TYPE).map(
  ([key, value]) => {
    return {
      value: key,
      label: value.label,
    };
  }
);

const viewOptions = ref([]);
viewOptions.value = Object.entries(ANALYSIS_DRAW_TYPE)
  .map(([key, value]) => {
    if (ANALYSIS_CHART_DRAW[props.data.resultType].includes(key)) {
      return {
        value: key,
        label: value.label,
      };
    }
  })
  .filter((item) => item);

// 监听传入data，提取数据
const chartData = ref(null);
const dataType = ref(ANALYSIS_DATA_DRAW.FREQUENCY);
const analysisChartData = ref(null);
watch(
  () => props.data,
  (newVal, oldVal) => {
    chartData.value = JSON.parse(JSON.stringify(props.data));
    // test
    // chartData.value = {
    //   intelligentAnalysis: null,
    //   resultData: {
    //     data: [
    //       {
    //         chartVariableName: "男",
    //         frequency: 60,
    //         percentage: 30,
    //       },
    //       {
    //         chartVariableName: "女",
    //         frequency: 140,
    //         percentage: 70,
    //       },
    //     ],
    //     title: "性别和学历",
    //   },
    //   resultType: 101,
    // };
    // chartData.value = {
    //   intelligentAnalysis: null,
    //   resultData: {
    //     data: [
    //       {
    //         chartVariableName: "男",
    //         childDataList: [
    //           {
    //             chartVariableName: "小学",
    //             frequency: 10,
    //             percentage: 20,
    //           },
    //           {
    //             chartVariableName: "中学",
    //             frequency: 20,
    //             percentage: 40,
    //           },
    //         ],
    //       },
    //       {
    //         chartVariableName: "女",
    //         childDataList: [
    //           {
    //             chartVariableName: "小学",
    //             frequency: 30,
    //             percentage: 60,
    //           },
    //           {
    //             chartVariableName: "中学",
    //             frequency: 40,
    //             percentage: 80,
    //           },
    //         ],
    //       },
    //     ],
    //     title: "性别和学历",
    //   },

    //   resultType: 301,
    // };

    chartData.value.resultType =
      ANALYSIS_CHART_DRAW[chartData.value.resultType][0];
    selectedView.value = ANALYSIS_DRAW_TYPE[chartData.value.resultType].label;
    analysisChartData.value = ANALYSIS_DRAW_TYPE[
      chartData.value.resultType
    ].generateChartData(chartData.value, dataType.value);
  },
  { immediate: true, deep: true }
);

// 数据类型改变后的重置图数据
const handleChangeDataType = (val) => {
  analysisChartData.value = ANALYSIS_DRAW_TYPE[
    chartData.value.resultType
  ].generateChartData(chartData.value, dataType.value);
};

// 设置图类型
const currentChartComponent = computed(() => {
  return ANALYSIS_DRAW_TYPE[chartData.value.resultType].chartComponent || null;
});
const currentCollapseComponent = computed(() => {
  return (
    ANALYSIS_DRAW_TYPE[chartData.value.resultType].collapseComponent || null
  );
});

// 图类型改变后的重置样式内容
const handleChangeView = (val) => {
  chartData.value.resultType = val;
  analysisChartData.value = ANALYSIS_DRAW_TYPE[
    chartData.value.resultType
  ].generateChartData(chartData.value, dataType.value);
};

// 图片样式修改
const handleChangeTitle = (newTitle) => {
  analysisChartData.value.title = newTitle;
};
const handleChangeLegendColor = (legendColor) => {
  analysisChartData.value.legendColor = legendColor;
};
const saveImage = (type) => {
  chartRef.value.exportChart(type);
};
</script>

<style lang="scss" scoped>
.analysis-result-chart {
  .analysis-result-chart-select {
    display: flex;
    justify-content: right;
    .analysis-result-chart-select-item {
      padding-left: 20px;
      width: 140px;
    }
  }
  .analysis-result-chart-view {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    .analysis-result-chart-content {
      height: 500px;
      width: 75%;
      display: flex;
      justify-content: center;
      .chart {
        height: 500px;
        width: 70%;
      }
    }
    .analysis-result-chart-option {
      width: 200px;
      .analysis-result-chart-option-title {
        line-height: 48px;
        font-weight: bold;
      }
      :deep(.el-collapse-item__header) {
        font-weight: normal;
      }
      .analysis-result-chart-option-button {
        display: grid;
        grid-template-columns: repeat(2, 45%);
        grid-gap: 10px;
        :deep(.el-button) {
          margin-left: 0;
        }
      }
      .legend-color {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .el-select {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
