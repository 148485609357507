<template>
  <!-- 柱状图 -->
  <div ref="chartRef" class="chart"></div>
</template>

<script setup>
import * as echarts from "echarts";
import {
  onMounted,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  watch,
  computed,
} from "vue";
import { generateGradientColors } from "@/utils/color.js";

const props = defineProps({
  data: {
    type: Object,
    default: () => {
      return {
        xAxisData: [],
        series: [],
        yAxis: [],
        xAxis: [],
      };
    },
  },
  title: {
    type: Object,
    default: () => {
      return {
        title: "",
        xTitle: "",
        yTitle: "",
      };
    },
  },
  legendColor: {
    type: Object,
    default: () => {
      return [];
    },
  },
});

const chartRef = ref(null);
let myChart;
let chartOption = ref({});
let defaultOption = {
  title: {
    text: "Profit",
    left: "center",
  },
  tooltip: {
    trigger: "item",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (params) {
      return "频数: " + params.data.value[2];
    },
  },
  legend: {
    type: "scroll",
    top: "10%",
  },
  grid: {
    top: "20%",
    containLabel: true,
  },
  xAxis: [
    {
      type: "value",
      scale: true,
      data: [],
      nameLocation: "middle",
      nameGap: 30,
    },
  ],
  yAxis: [
    {
      type: "value",
      nameLocation: "middle",
      nameGap: 30,
    },
  ],
  series: [
    {
      type: "custom",
      renderItem: function (params, api) {
        var yValue = api.value(2);
        var start = api.coord([api.value(0), yValue]);
        var size = api.size([api.value(1) - api.value(0), yValue]);
        var style = api.style();
        return {
          type: "rect",
          shape: {
            x: start[0],
            y: start[1],
            width: size[0],
            height: size[1],
          },
          style: style,
        };
      },
      label: {
        show: true,
        position: "top",
      },
      dimensions: ["from", "to", "frequency"],
      // dimensions: ["frequency"],
      encode: {
        x: [0, 1],
        y: 2,
        tooltip: [2],
      },
      data: null,
      itemStyle: {
        color: null,
      },
    },
  ],
};

const seriesItem = ref({
  name: null,
  data: [],
  type: "bar",
  stack: null,
  itemStyle: {
    color: null,
  },
});

const chartData = ref(null);
const chartTitle = ref(null);
const chartLegendColor = ref(null);

onMounted(() => {
  initChartView();
});

// 初始化图片，使用父组件传入的值
const initChartView = () => {
  myChart = echarts.init(chartRef.value);
  chartOption.value = defaultOption;
  // 设置标题
  chartOption.value.title.text = chartTitle.value.title;
  if (chartOption.value.xAxis[0]) {
    chartOption.value.xAxis[0].name = chartTitle.value.xTitle;
  }
  if (chartOption.value.yAxis) {
    // chartOption.value.yAxis[0].name = chartTitle.value.yTitle;
    chartOption.value.yAxis[0].name = "频数";
  }

  // 设置坐标轴数据

  // 设置数据
  chartData.value.series.forEach((element, index) => {
    if (chartOption.value.series[index]) {
      chartOption.value.series[index] = {
        ...chartOption.value.series[index],
        ...element,
        data: element.data.map(function (item, index) {
          return {
            value: item,
          };
        }),
      };
    } else {
      chartOption.value.series[index] = { ...seriesItem.value, ...element };
    }
  });

  // 设置图例颜色
  if (chartOption.value.series.length == 1) {
    chartOption.value.series[0].itemStyle.color = function (params) {
      let colorList = chartLegendColor.value;
      return colorList[params.dataIndex];
    };
  } else {
    chartOption.value.series.forEach((element, index) => {
      chartOption.value.series[index].itemStyle.color =
        chartLegendColor.value[index];
    });
  }

  if (chartData.value.yAxis.length == 1) {
    chartOption.value.yAxis.push(chartData.value.yAxis[0]);
  }
  if (chartData.value.xAxis.length == 1) {
    chartOption.value.xAxis.push(chartData.value.xAxis[0]);
  }
  if (myChart) {
    // 渲染
    myChart.setOption(chartOption.value);
  }
};

// 设置图数据
const setChartData = () => {
  chartOption.value.series = chartData.value.series.map((element, index) => {
    let newSeriesItem;
    if (chartOption.value.series[index]) {
      newSeriesItem = JSON.parse(
        JSON.stringify(chartOption.value.series[index])
      );
    } else {
      newSeriesItem = JSON.parse(JSON.stringify(seriesItem.value));
    }
    newSeriesItem = { ...newSeriesItem, ...element };
    return newSeriesItem;
  });

  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

// 设置图标题
const setChartTitle = () => {
  chartOption.value.title.text = chartTitle.value.title;
  if (chartOption.value.xAxis[0]) {
    chartOption.value.xAxis[0].name = chartTitle.value.xTitle;
  }
  if (chartOption.value.yAxis[0]) {
    chartOption.value.yAxis[0].name = chartTitle.value.yTitle;
  }
  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

// 设置图例
const setLegendColor = () => {
  if (
    chartOption.value.series.length == 1 &&
    chartOption.value.series[0].type == "bar"
  ) {
    chartOption.value.series[0].itemStyle.color = function (params) {
      let colorList = chartLegendColor.value;
      return colorList[params.dataIndex];
    };
  } else {
    chartOption.value.series = chartOption.value.series.map(
      (element, index) => {
        let newElement = JSON.parse(JSON.stringify(element));
        newElement.itemStyle.color = chartLegendColor.value[index];
        return newElement;
      }
    );
  }

  if (myChart) {
    myChart.setOption(chartOption.value);
  }
};

// 图片导出
const exportChart = (type) => {
  let imgUrl = myChart.getDataURL({
    type: type,
    backgroundColor: "#fff",
    pixelRatio: 2,
  });
  var title = chartOption.value.title.text;
  var name =
    title == "" || title == null || title == undefined
      ? new Date().getTime()
      : title;
  if (window.navigator.msSaveOrOpenBlob) {
    var bstr = atob(imgUrl.split(",")[1]);
    var n = bstr.length;
    var u8arr = new Blob([u8arr]);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    if (type == "jpeg" || type == "jpg") {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".jpg");
    } else {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".png");
    }
  } else {
    const a = document.createElement("a");
    a.href = imgUrl;
    if (type == "jpeg" || type == "jpg") {
      a.setAttribute("download", name + ".jpg");
    } else {
      a.setAttribute("download", name + ".png");
    }
    a.click();
  }
};

watch(
  () => props.data,
  (newVal, oldVal) => {
    chartData.value = JSON.parse(JSON.stringify(props.data));
    if (myChart) {
      setChartData();
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.title,
  (newVal, oldVal) => {
    chartTitle.value = JSON.parse(JSON.stringify(props.title));
    if (myChart) {
      setChartTitle();
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.legendColor,
  (newVal, oldVal) => {
    chartLegendColor.value = JSON.parse(JSON.stringify(props.legendColor));
    if (myChart) {
      setLegendColor();
    }
  },
  { immediate: true, deep: true }
);

defineExpose({ exportChart });
</script>

<style lang="scss" scoped></style>
