<template>
  <div class="native-table-wrapper">
    <table border="0" class="native-table">
      <thead v-if="showHeader">
        <tr v-for="(trItem, trIndex) in tableHeaders" :key="trIndex">
          <th
            v-for="(thItem, thIndex) in trItem"
            :key="thIndex"
            :colspan="thItem.colspan"
            :rowspan="thItem.rowspan"
          >
            {{ thItem.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(trItem, trIndex) in rowData" :key="trIndex">
          <template
            v-for="(tdItem, tdIndex) in tableHeaderLeafList"
            :key="tdIndex"
          >
            <td
              :rowspan="
                spanMethod({
                  row: trItem,
                  column: tdItem,
                  rowIndex: trIndex,
                  columnIndex: tdIndex,
                }).rowspan
              "
              :colspan="
                spanMethod({
                  row: trItem,
                  column: tdItem,
                  rowIndex: trIndex,
                  columnIndex: tdIndex,
                }).colspan
              "
              v-if="
                spanMethod({
                  row: trItem,
                  column: tdItem,
                  rowIndex: trIndex,
                  columnIndex: tdIndex,
                }).colspan != 0 &&
                spanMethod({
                  row: trItem,
                  column: tdItem,
                  rowIndex: trIndex,
                  columnIndex: tdIndex,
                }).colspan != 0
              "
            >
              <!-- {{ trItem[tableHeaderLeafList[tdIndex].value] }} -->
              <template
                v-if="ifCanEdit(tableHeaderLeafList[tdIndex].value, trIndex)"
              >
                <span style="z-index: -1; position: absolute">
                  {{ trItem[tableHeaderLeafList[tdIndex].value] }}
                </span>
                <el-input
                  v-model="trItem[tableHeaderLeafList[tdIndex].value]"
                  placeholder="请输入您的预测值"
                  type="number"
                ></el-input>
              </template>

              <template
                v-else-if="
                  ifPredict(tableHeaderLeafList[tdIndex].value, trIndex)
                "
              >
                {{
                  getPredictValue(tableHeaderLeafList[tdIndex].value, trIndex)
                }}
              </template>
              <template v-else>
                {{ trItem[tableHeaderLeafList[tdIndex].value] }}
              </template>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  onMounted,
  inject,
  computed,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { isArray } from "element-plus/es/utils";

const props = defineProps({
  // 树形结构的表头
  tableTitles: {
    type: Array,
    default: () => [],
  },
  // 表格数据
  rowData: {
    type: Array,
    default: () => [],
  },
  spanMethod: {
    type: Function,
    default: () => {
      return {
        rowspan: 1,
        colspan: 1,
      };
    },
  },
  // 表示是否为输入预测参数的map
  edit: {
    type: Object,
  },
  // 表示是否为预测值的map
  predict: {
    type: Object,
  },
  showHeader: {
    type: Boolean,
    default: () => true,
  },
});

const allData = inject("allData");

const tableHeaders = ref([]); // 记录th元素的数组
const tableHeaderLeafList = ref([]); // 表头叶节点的数组

const generateTableHeadersInfo = () => {
  tableHeaders.value = [];
  let tableTitles = props.tableTitles;
  // TODO 测试用例

  for (let tree of tableTitles) {
    //获取每个节点的叶节点个数
    getLeavesOfTree(tree);
  }
  generateTableHeaders(tableTitles);
};
const generateTableHeaders = (tableTitles) => {
  let levelTraversalQueue = JSON.parse(JSON.stringify(tableTitles)); //层序遍历栈
  let level = 0;
  while (levelTraversalQueue.length > 0) {
    const currentLevelSize = levelTraversalQueue.length; // 当前层级节点个数
    console.log("level length", currentLevelSize);
    const currentLevelNodeList = []; // 当前层节点列表
    for (let i = 0; i < currentLevelSize; i++) {
      const currentNode = levelTraversalQueue.shift();
      currentLevelNodeList.push(currentNode);
      if (currentNode.children) {
        levelTraversalQueue = levelTraversalQueue.concat(currentNode.children);
      } else {
        currentNode.rowspan = maxDepth.value - level;
      }
    }

    tableHeaders.value.push(currentLevelNodeList);
    level++;
  }
};

// 获取节点中除去children后的所有属性
const getTreeNodeData = (node) => {
  let nodeData = JSON.parse(JSON.stringify(node));
  delete nodeData.children;
  return nodeData;
};
// 获取子树的叶节点个数
const getLeavesOfTree = (node) => {
  if (!node.children || node.children.length == 0) {
    node.colspan = 1;
    tableHeaderLeafList.value.push(getTreeNodeData(node));

    return node.colspan;
  }
  node.colspan = 0;
  for (let subTree of node.children) {
    node.colspan += getLeavesOfTree(subTree);
  }
  return node.colspan;
};
const maxDepth = computed(() => {
  let result = [];
  for (let tree of props.tableTitles) {
    result.push(getMaxDepth(tree));
  }
  return Math.max(...result);
});
// 获取树的最大深度（层数）
function getMaxDepth(root) {
  if (root === null) return 0;
  let depth = 0;

  if (root.children) {
    for (let i = 0; i < root.children.length; i++) {
      depth = Math.max(depth, getMaxDepth(root.children[i]));
    }
  }
  return depth + 1;
}
// 计算单元格是否可编辑
const ifCanEdit = computed(() => (column, rowIndex) => {
  return props.edit
    ? props.edit[column]
      ? props.edit[column][rowIndex]
        ? true
        : false
      : false
    : false;
});
// 计算单元格是否显示预测值
const ifPredict = computed(() => (column, rowIndex) => {
  return props.predict
    ? props.predict[column]
      ? props.predict[column][rowIndex]
        ? true
        : false
      : false
    : false;
});
const getPredictValue = computed(() => {
  return (column, rowIndex) => {
    console.log(column, rowIndex, props.predict[column][rowIndex]);
    let sum = 0;
    let { sumItemList } = props.predict[column][rowIndex];
    sumItemList.forEach((sumItem) => {
      // console.log(
      //   "sumitem",
      //   sumItem.factor,
      //   allData.value[sumItem.rowIndex][sumItem.colName] || 0
      // );
      sum +=
        sumItem.factor *
        (allData.value[sumItem.rowIndex][sumItem.colName] || 0);
    });

    // props.
    return sum.toFixed(3);
  };
});
onMounted(() => {
  generateTableHeadersInfo();
});
</script>

<style lang="scss" scoped>
.native-table-wrapper {
  width: 100%;
  overflow: scroll;
  .native-table {
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
    min-width: 100%;
    table-layout: fixed;
    overflow: scroll;
    border-collapse: collapse;
    td,
    th {
      // width: 80px;
      min-width: 80px;
      white-space: normal;
      word-break: break-all;
      text-align: center;
      line-height: 24px;
      padding: 8px 0;
    }
    tr {
      display: table-row;
    }
    th {
      border-bottom: 1px solid #000000;
    }
    td {
      white-space: normal;
      word-break: break-all;
      text-align: center;
      overflow: hidden;
    }
    thead {
      display: table-header-group;
      vertical-align: middle;
    }
    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;
    }
  }
}
</style>
